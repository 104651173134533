import { LOGIN, LOGOUT, POST_USER_RESPONSE } from "../constants/actionTypes";

const defaultState = {
  token: "6f96dc6b798807a3792d0bee126a494ab97d38d3",
  user_id: "fd6da0bc-3fb9-4cf6-8167-685e731d9173",
  email: "admin@clapingo.com",
  phone: "",
  user_name: "",
  user_type: "",
  first_name: "",
  last_name: "",
};
let userData = {};
export default (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN:
      userData = { ...state };
      userData = action.payload;
      return userData;
    case LOGOUT:
      userData = { ...state };
      userData = action.payload;
      return userData;
    case POST_USER_RESPONSE:
      userData = { ...state };
      userData.userResponse = action.payload;
      return userData;
    default:
      return { ...state };
  }
};
