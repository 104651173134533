import {
  CREATE_PROJECT,
  DELETE_PROJECT,
  GET_PROJECTS,
} from "../constants/actionTypes";

const defaultState = {
  projects: [],
};
let projectsData = {};
export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_PROJECTS:
      projectsData = { ...state };
      projectsData.projects = action.payload;
      return projectsData;
    case CREATE_PROJECT:
      projectsData = { ...state };
      projectsData.projects = [...projectsData.projects, action.payload];
      return projectsData;
    case DELETE_PROJECT:
      projectsData = { ...state };
      projectsData.projects = [
        ...projectsData?.projects.filter(
          (project) => project.id != action.payload,
        ),
      ];
      return projectsData;
    default:
      return { ...state };
  }
};
