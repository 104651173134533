export const LOGIN_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/login/`;
export const SIGNUP_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/users/`;
export const GET_PROJECTS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/projects/`;
export const CREATE_PROJECT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/projects/`;
export const DELETE_PROJECT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/projects/`;
export const GET_PROJECT_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/projects/`;
export const CREATE_NODE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/node/`;
export const UPDATE_NODE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/node/`;
export const DELETE_NODE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/node/`;
export const CREATE_CHOICE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/choice/`;
export const UPDATE_CHOICE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/choice/`;
export const DELETE_CHOICE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/choice/`;
export const STORE_USER_RESPONSE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/user_responses/`;
export const POST_USER_RESPONSE_API = `https://pronunciation.clapingo.com/pronunciation_diagnosis/`;
