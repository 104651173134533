import React, { useEffect, useRef, useState } from "react";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import styles from "../css/joiningScreen.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleErrorMessage } from "../redux/actions/projects";
import ApiResponseAlert from "./ApiResponseAlert";
import { Tooltip } from "@mui/material";
import { LOGIN } from "../redux/constants/actionTypes";

const JoiningScreen = () => {
  const [isCameraOn, setIsCameraOn] = useState(true);
  const [isMicOn, setIsMicOn] = useState(true);
  const [isCameraAvailable, setIsCameraAvailable] = useState(false);
  const [isMicAvailable, setIsMicAvailable] = useState(false);
  const [media, setMedia] = useState(null);

  const videoRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { id, userToken } = useParams();
  const { id } = useParams();

  useEffect(() => {
    // const data = {
    //   token: userToken,
    //   user_id: "fd6da0bc-3fb9-4cf6-8167-685e731d9173",
    //   email: "admin@clapingo.com",
    // };
    // dispatch({
    //   type: LOGIN,
    //   payload: data,
    // });
    const getMedia = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        setMedia(stream);
        setIsCameraAvailable(true);
        setIsMicAvailable(true);
      } catch (error) {
        dispatch(
          handleErrorMessage("Make sure to give camera and microphone access"),
        );
        console.error("Error accessing webcam and microphone:", error);
        setIsCameraAvailable(false);
        setIsMicAvailable(false);
      }
    };
    getMedia();
  }, []);
  useEffect(() => {
    if (media) {
      videoRef.current.srcObject = media;
      videoRef.current.play();
    }
  }, [media]);

  // Function to turn on the webcam stream
  function turnOnCamera() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          setMedia(stream);
        })
        .catch(function (error) {
          dispatch(handleErrorMessage("Error accessing media device"));
          console.error("Error accessing media devices:", error);
        });
    } else {
      dispatch(
        handleErrorMessage("getUserMedia is not supported by this browser"),
      );
      console.error("getUserMedia is not supported by this browser");
    }
  }

  // Function to turn off the webcam stream
  function turnOffCamera() {
    if (media) {
      media.getTracks().forEach((track) => track.stop());
      setMedia(null);
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    }
  }

  return (
    <>
      <ApiResponseAlert />
      <div className={styles.header}>
        <img
          src="/assets/Clapingo.svg"
          alt="clapingo-logo"
          className={styles.clapingoLogo}
        />
      </div>
      <div className={styles.container}>
        <div className={styles.headingContainer}>
          <div className={styles.heading1}>Let's check everything...</div>
          <div className={styles.heading2}>
            Make sure your camera and microphone are working
          </div>
        </div>
        <div className={styles.videoContainer}>
          {isCameraOn ? (
            <video
              ref={videoRef}
              playsInline
              muted
              autoPlay
              className={styles.video}
            />
          ) : (
            <div className={styles.dummyVideoScreen}>
              <img
                src="/assets/Body.svg"
                alt="Body"
                className={styles.bodyIcon}
              />
              <div className={styles.heading3}>Camera is off</div>
            </div>
          )}
          <div className={styles.iconsContainer}>
            {isCameraOn ? (
              <span
                className={styles.outerCirce}
                onClick={() => {
                  turnOffCamera();
                  setIsCameraOn(!isCameraOn);
                }}
              >
                <VideocamIcon style={{ margin: "10px" }} />
              </span>
            ) : (
              <span
                className={styles.outerCirce}
                onClick={() => {
                  turnOnCamera();
                  setIsCameraOn(!isCameraOn);
                }}
              >
                <VideocamOffIcon style={{ margin: "10px" }} />
              </span>
            )}
            <span
              className={styles.outerCirce}
              onClick={() => {
                setIsMicOn(!isMicOn);
              }}
            >
              {isMicOn ? (
                <MicIcon style={{ margin: "10px" }} />
              ) : (
                <MicOffIcon style={{ margin: "10px" }} />
              )}
            </span>
            <Tooltip
              title={
                isCameraAvailable && isMicAvailable
                  ? ""
                  : "Make sure to give camera and microphone access"
              }
              arrow
              placement="top"
            >
              <button
                className={styles.startButton}
                onClick={() => {
                  if (isCameraAvailable && isMicAvailable) {
                    sessionStorage.setItem("cameraOn", isCameraOn);
                    sessionStorage.setItem("micOn", isMicOn);
                    turnOffCamera();
                    localStorage.removeItem("firstLoadDone");
                    // navigate(`/play/${id}/${userToken}`);
                    navigate(`/play/${id}`);
                  }
                }}
                style={{
                  cursor: !(isCameraAvailable && isMicAvailable)
                    ? "not-allowed"
                    : "pointer",
                }}
              >
                Start Class
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoiningScreen;
