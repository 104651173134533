import { React, useRef, useState } from "react";
import styles1 from "../css/signup.module.css";
import styles from "../css/newProjectForm.module.css";
import axios from "axios";
import ApiResponseAlert from "./ApiResponseAlert";
import { handleErrorMessage } from "../redux/actions/projects";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createNewUser, loginUser } from "../redux/actions/user";

export default function Signup() {
  // States for registration
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const token = useSelector((state) => state?.user?.token);
  const error = useSelector((state) => state?.apiResponse?.errorMsg);
  const errorRef = useRef();
  errorRef.current = error;
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("STUDENT");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
  };

  // Handling the name change
  const handleFirstName = (e) => {
    setFirstName(e.target.value);
  };
  const handleLastName = (e) => {
    setLastName(e.target.value);
  };

  // Handling the email change
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  // Handling the password change
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  // Handling the form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      first_name === "" ||
      email === "" ||
      userType === "" ||
      password === ""
    ) {
      dispatch(handleErrorMessage("Please enter all the fields"));
    } else {
      const data = {
        user_name: first_name + " " + last_name,
        first_name,
        last_name,
        email,
        user_type: userType,
        password,
      };
      const loginData = {
        username: email,
        password,
      };
      dispatch(createNewUser(data, loginData)).then(() => {
        if (errorRef.current === "") {
          dispatch(loginUser(loginData)).then(() => {
            if (errorRef.current === "") {
              navigate("/");
            }
          });
        }
      });
    }
  };
  return (
    <div className={styles1.outerFormContainer}>
      <div className={styles.formContainer}>
        <div>
          <h1>User Signup</h1>
          <p>Please fill in this form to sign up.</p>
        </div>
        <hr className={styles.NewProjectHr} />
        <ApiResponseAlert />
        <form>
          {/* Labels and inputs for form data */}
          <label>
            <b>First Name</b>
          </label>
          <input
            onChange={handleFirstName}
            className={styles.descriptionInput}
            placeholder={"Enter Your First Name"}
            value={first_name}
            type="text"
          />
          <label>
            <b>Last Name</b>
          </label>
          <input
            onChange={handleLastName}
            className={styles.descriptionInput}
            placeholder={"Enter Your Last Name"}
            value={last_name}
            type="text"
          />
          <label>
            <b>User Type</b>
          </label>
          <select
            onChange={handleUserTypeChange}
            className={styles1.descriptionSelectionInput}
          >
            <option value="TEACHER">Teacher</option>
            <option value="STUDENT">Student</option>
          </select>
          <label>
            <b>Email</b>
          </label>
          <input
            onChange={handleEmail}
            className={styles.descriptionInput}
            placeholder={"Enter Email"}
            value={email}
            type="email"
          />

          <label>
            <b>Password</b>
          </label>
          <input
            onChange={handlePassword}
            className={styles.descriptionInput}
            placeholder={"Enter Password"}
            value={password}
            type="password"
          />
          <hr className={styles.NewProjectHr} />
          <button
            onClick={handleSubmit}
            className={styles.submitButton}
            type="submit"
          >
            Submit
          </button>
        </form>
        <div className={styles1.separator}>or</div>
        <div className={styles1.bottomSigninText}>
          Already Have an account?
          <span onClick={() => navigate("/signin")}>
            <b style={{ cursor: "pointer" }}> Sign in</b>
          </span>
        </div>
      </div>
    </div>
  );
}
