import { Drawer } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import { isEdge, isNode, ReactFlowProvider } from "react-flow-renderer";
import { useDispatch, useSelector } from "react-redux";
import {
  createEdge,
  createNode,
  deleteEdge,
  deleteNode,
  getProjectDetails,
} from "../redux/actions/nodes";
import styles from "../css/projectPage.module.css";
import Header from "./Header";
import Node from "./Node";
import NodeDetailsForm from "./NodeDetailsForm";
import ReactFlow, {
  removeElements,
  addEdge,
  Controls,
  Background,
} from "react-flow-renderer";
import ApiResponseAlert from "./ApiResponseAlert";

const onLoad = (reactFlowInstance) => {
  reactFlowInstance.fitView();
};

export default function ProjectPage() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedNode, setSelectedNode] = useState(0);
  const [elements, setElements] = useState([]);
  const [selectedNodeData, setSelectedNodeData] = useState({});
  const projectId = window.location.pathname.split("/").pop();
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state?.nodes?.nodes);
  const edges = useSelector((state) => state?.nodes?.edges);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  useEffect(() => {
    dispatch(getProjectDetails(projectId)).then(() => {
      return nodes.length > 0
        ? setElements([...nodes, ...edges])
        : setElements([]);
    });
  }, [nodes.length, edges.length]);

  const onElementsRemove = (elementsToRemove) => {
    if (isEdge(elementsToRemove[0])) {
      dispatch(deleteEdge(elementsToRemove[0]?.id)).then(() =>
        setElements([...nodes, ...edges]),
      );
    }
  };
  const onNodesRemove = (nodeId) => {
    dispatch(deleteNode(nodeId)).then(() => {
      setElements([...nodes, ...edges]);
      setOpenDrawer(false);
    });
  };

  const onConnect = (params) => {
    console.log("params", params);
    dispatch(createEdge({ source: params.source, target: params.target })).then(
      () => setElements([...nodes, ...edges]),
    );
  };

  // const onRestore = useCallback(() => {
  //   const restoreFlow = async () => {
  //     await dispatch(getProjectDetails(window.location.pathname.split('/').pop()));
  //     if (nodes) {
  //       console.log("wer",elements,nodes)
  //       // const [x = 0, y = 0] = flow.position;
  //       setElements(nodes || []);
  //       console.log("wer1",elements,nodes)
  //       // transform({ x, y, zoom: flow.zoom || 0 });
  //     }
  //   };

  //   restoreFlow();
  // }, [setElements]);

  const nodeTypes = {
    special: Node,
  };

  const elementOnClick = (node) => {
    if (isNode(node)) {
      setSelectedNode(node?.id);
      setSelectedNodeData(node?.position);
      setOpenDrawer(true);
    }
  };

  const addNode = () => {
    const last_position_x = nodes[nodes.length - 1]?.position?.x || 100;
    const last_position_y = nodes[nodes.length - 1]?.position?.y || 100;
    const newNodeData = {
      name: "Add Name",
      associated_project: projectId,
      description: "Add Description",
      position_x: last_position_x + 200,
      position_y: last_position_y + 100,
      drag_and_drop_matching: "[]",
    };
    dispatch(createNode(newNodeData, nodes?.length)).then(() => {
      setElements([...nodes, ...edges]);
      console.log("elements", elements);
    });
  };
  // console.log("rendered", nodes);
  return (
    <ReactFlowProvider>
      <div className={styles.outerContainer}>
        <ApiResponseAlert />
        <Header addNode={addNode} />
        <Drawer
          open={openDrawer}
          anchor={"right"}
          onClose={() => toggleDrawer()}
          style={{ width: "100%", height: "100%" }}
        >
          <NodeDetailsForm
            key={selectedNode}
            nodeData={nodes.filter((node) => node.id == selectedNode)[0]}
            selectedNodeDataPosition={selectedNodeData}
            onElementsRemove={onNodesRemove}
            edges={edges
              .filter((edge) => edge.source == selectedNode)
              .map((edge, index) => {
                edge = { ...edge };
                edge.choiceNumber = index + 1;
                return edge;
              })}
            nodes={nodes}
            projectId={projectId}
          />
        </Drawer>
        <div className={styles.reactFlowContainer}>
          <ReactFlow
            elements={elements}
            zoomOnDoubleClick={false}
            onElementsRemove={onElementsRemove}
            onConnect={onConnect}
            onLoad={onLoad}
            nodeTypes={nodeTypes}
            onElementClick={(event, element) => elementOnClick(element)}
            snapToGrid={true}
            snapGrid={[15, 15]}
          >
            <Controls />
            <Background color="#aaa" gap={8} />
          </ReactFlow>
        </div>
      </div>
    </ReactFlowProvider>
  );
}
