import {
  GET_SUCCESS_MESSAGE,
  GET_ERROR_MESSAGE,
} from "../constants/actionTypes";

const defaultState = {
  successMsg: "",
  errorMsg: "",
};
let apiResponseData = {};
export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_ERROR_MESSAGE:
      apiResponseData = { ...state };
      apiResponseData.errorMsg = action.payload;
      apiResponseData.successMsg = "";
      return apiResponseData;
    case GET_SUCCESS_MESSAGE:
      apiResponseData = { ...state };
      apiResponseData.successMsg = action.payload;
      apiResponseData.errorMsg = "";
      return apiResponseData;
    default:
      return { ...state };
  }
};
