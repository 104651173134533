import { ReactMic } from "react-mic";
import React from "react";
export class Recorder extends React.Component {
  constructor(props) {
    super(props);
    this.onStop = this.onStop.bind(this);
    this.state = {
      record: false,
    };
  }
  componentDidMount = () => {
    this.startRecording();
    setTimeout(
      () => this.stopRecording(),
      (this.props.timerDuration - 2) * 1000,
    );
  };

  startRecording = () => {
    this.setState({ record: true });
  };

  stopRecording = () => {
    this.setState({ record: false });
  };

  onData(recordedBlob) {
    //console.log('chunk of real-time data is: ', recordedBlob);
  }

  onStop = (recordedBlob) => {
    this.props.setRecordedBlob(recordedBlob);
    this.props.sendRecordedBlob();
  };
  render() {
    return (
      <>
        <div style={{ marginTop: 32, display: "hidden" }}>
          <ReactMic
            record={this.state.record}
            className="sound-wave"
            onStop={this.onStop}
            onData={this.onData}
            channelCount={1}
            strokeColor="#00000000"
            mimeType="audio/wav"
            backgroundColor="#00000000"
          />
        </div>
      </>
    );
  }
}
