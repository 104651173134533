import axios from "axios";
import { store } from "../store";
import { LOGIN, LOGOUT, POST_USER_RESPONSE } from "../constants/actionTypes";
import {
  LOGIN_API,
  POST_USER_RESPONSE_API,
  SIGNUP_API,
  STORE_USER_RESPONSE_API,
} from "../constants/apiEndpoints";
import { handleErrorMessage, handleSuccessMessage } from "./projects";

export const loginUser = (data) => {
  return async (dispatch) => {
    await axios({
      method: "post",
      url: LOGIN_API,
      data,
      headers: {},
    })
      .then((response) => {
        if (response.status) {
          localStorage.setItem("UserDataToken", response?.data?.data?.token);
          localStorage.setItem(
            "UserData",
            JSON.stringify(response?.data?.data),
          );
          dispatch(handleSuccessMessage("User logged in Successfully!"));
          dispatch({
            type: LOGIN,
            payload: response.data?.data,
          });
        }
      })
      .catch(async (error) => {
        localStorage.clear();
        dispatch(handleErrorMessage(error));
        return error;
      });
  };
};
export const createNewUser = (data) => {
  return async (dispatch) => {
    await axios({
      method: "post",
      url: SIGNUP_API,
      data,
      headers: {},
    })
      .then((response) => {
        if (response.status) {
          dispatch(handleSuccessMessage("User Created Successfully!"));
          return response;
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(error));
        return error;
      });
  };
};
export const logoutUser = () => {
  return async (dispatch) => {
    localStorage.clear();
    dispatch({
      type: LOGOUT,
      payload: {},
    });
    dispatch(handleSuccessMessage("User Logged Out Successfully!"));
    return true;
  };
};
export const postUserResponse = (data) => {
  return async (dispatch) => {
    await axios({
      method: "post",
      url: POST_USER_RESPONSE_API,
      data: data,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        if (response.status >= 200) {
          dispatch({
            type: POST_USER_RESPONSE,
            payload: response?.data,
          });
          return true;
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(error));
        return error;
      });
  };
};
export const storeUserResponse = (data) => {
  return async (dispatch) => {
    const token = store.getState()?.user?.token;
    const response = await axios({
      method: "post",
      url: STORE_USER_RESPONSE_API,
      data: data,
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
    if (!(response.status >= 200 && response.status <= 300)) {
      console.log("user data not stored", response);
    }
  };
};
