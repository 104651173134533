import React, { useState, useEffect } from "react";
import Signin from "./Signin";
import { Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../redux/actions/user";
import { LOGIN } from "../redux/constants/actionTypes";

export default function AuthenticatedRoute({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const token = useSelector((state) => state?.user?.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let tokenFromStorage = localStorage.getItem("UserDataToken");
    let userDataFromStorage = JSON.parse(localStorage.getItem("UserData"));
    setIsAuthenticated(tokenFromStorage ? true : false);
    if (userDataFromStorage?.email && tokenFromStorage) {
      dispatch({
        type: LOGIN,
        payload: userDataFromStorage,
      });
      navigate("/");
    }
  }, [token]);

  return <>{isAuthenticated ? <>{children}</> : <Signin />}</>;
}
