export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const GET_PROJECTS = "GET_PROJECTS";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const GET_PROJECT_DETAILS = "GET_PROJECT_DETAILS";
export const CREATE_NODE = "CREATE_NODE";
export const UPDATE_NODE = "UPDATE_NODE";
export const DELETE_NODE = "DELETE_NODE";
export const CREATE_CHOICE = "CREATE_CHOICE";
export const UPDATE_CHOICE = "UPDATE_CHOICE";
export const DELETE_CHOICE = "DELETE_CHOICE";
export const GET_ERROR_MESSAGE = "GET_ERROR_MESSAGE";
export const GET_SUCCESS_MESSAGE = "GET_SUCCESS_MESSAGE";
export const POST_USER_RESPONSE = "POST_USER_RESPONSE";
export const UPDATE_VIDEO_LINK = "UPDATE_VIDEO_LINK";
