import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import Home from "./components/Home";
import JoiningScreen from "./components/JoiningScreen";
import PlayScreen from "./components/PlayScreen";
import ProjectPage from "./components/ProjectPage";
import Signin from "./components/Signin";
import Signup from "./components/Signup";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import MobileBlocker from "./components/MobileBlocker";
import { Analytics } from "@vercel/analytics/react";

export default function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#F33A6A",
      },
      secondary: {
        main: "#C9E4DA",
      },
      mui: {
        main: "#1976d2",
      },
    },
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/signup" element={<Signup />} />
            <Route path="/signin" element={<Signin />} />
            <Route
              path="/project/:id"
              element={
                <AuthenticatedRoute>
                  <ProjectPage />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/"
              element={
                <AuthenticatedRoute>
                  <Home />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/joiningscreen/:id/:userToken"
              element={
                <MobileBlocker>
                  <JoiningScreen />
                </MobileBlocker>
              }
            />
            <Route
              path="/play/:projectId/:userToken"
              element={
                <MobileBlocker>
                  <PlayScreen />
                </MobileBlocker>
              }
            />
            <Route
              path="/joiningscreen/:id"
              element={
                <MobileBlocker>
                  <JoiningScreen />
                </MobileBlocker>
              }
            />
            <Route
              path="/play/:projectId"
              element={
                <MobileBlocker>
                  <PlayScreen />
                </MobileBlocker>
              }
            />
          </Routes>
        </Router>
      </ThemeProvider>
      <Analytics />
    </>
  );
}
