import React, { useEffect, useState } from "react";

const MobileBlocker = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobileDevice = windowWidth <= 500;
  if (isMobileDevice) {
    return <h1>Please open this application on a desktop device.</h1>;
  }

  return children;
};

export default MobileBlocker;
