import React, { useEffect, useRef, useState, useCallback } from "react";
import styles from "../css/playScreen.module.css";
import { getProjectDetails, updateVideoLink } from "../redux/actions/nodes";
import { useDispatch, useSelector } from "react-redux";
import { postUserResponse, storeUserResponse } from "../redux/actions/user";
import { Recorder } from "./Recorder";
import { store } from "../redux/store";
import { useNavigate, useParams } from "react-router-dom";
import MatchingPlayGround from "./MatchingPlayGround";
import { Backdrop, CircularProgress } from "@mui/material";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import { handleErrorMessage } from "../redux/actions/projects";
import ApiResponseAlert from "./ApiResponseAlert";
import DragAndDropPlayGround from "./DragAndDropPlayGround";
import { Image, Layer, Stage } from "react-konva";
import QuestionBox from "./QuestionBox";

const PlayScreen = () => {
  // const { projectId, userToken } = useParams();
  const { projectId } = useParams();

  // Redux
  const nodes = useSelector((state) => state?.nodes?.nodes);
  const edges = useSelector((state) => state?.nodes?.edges);
  const projectsList = useSelector((state) => state.projects?.projects);
  const currentUser = useSelector((state) => state?.user?.user_id);
  const userResponse = useSelector((state) => state?.user?.userResponse);
  // States
  const [displayChoices, setDisplayChoices] = useState(false);
  const [source, setSource] = useState(
    "http://media.w3.org/2010/05/sintel/trailer.mp4",
  );
  const [currentNode, setCurrentNode] = useState({});
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [startRecording, setStartRecording] = useState(false);
  const [isMatching, setIsMatching] = useState(false);
  const [isDnD, setIsDnD] = useState(false);
  const [isColorPresent, setIsColorPresent] = useState(false);
  const [isCameraOn, setIsCameraOn] = useState(
    sessionStorage.getItem("cameraOn") === "true",
  );
  const [isMicOn, setIsMicOn] = useState(
    sessionStorage.getItem("micOn") === "true",
  );
  const [projectName, setProjectName] = useState("");
  const [media, setMedia] = useState(null);
  const [isPronunciation, setIsPronunciation] = useState(false);
  const [videoNode, setVidoeNode] = useState();
  const [score, setScore] = useState(0);
  const [isRefreshed, setIsRefreshed] = useState(false);

  // Refs
  const videoRef = useRef(null);
  const myVideo = useRef(null);
  // const canvas = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
    const firstLoadDone = localStorage.getItem("firstLoadDone");
    if (firstLoadDone === null || firstLoadDone === "undefined") {
      localStorage.setItem("firstLoadDone", 1);
    } else {
      // navigate("/joiningscreen/" + projectId + "/" + userToken);
      navigate("/joiningscreen/" + projectId);
    }
  }, []);
  // useEffect used to get the first node or the last saved node
  useEffect(() => {
    const currentNodeId = localStorage.getItem("currentNodeId");
    if (
      currentNodeId !== "undefined" &&
      currentNodeId !== null &&
      !currentNode?.id
    ) {
      dispatch(getProjectDetails(projectId)).then(() => {
        const currentSource = nodes.find(
          (node) => node.id === JSON.parse(currentNodeId),
        );
        console.log(currentSource);
        if (currentSource !== undefined) {
          setCurrentNode({ ...currentSource });
          setIsRefreshed(true);
        } else {
          const startingSource = nodes.find((node) => node.is_start === true);
          const projectName = projectsList.find(
            (project) => project.id === projectId,
          );
          setProjectName(projectName?.name);
          setCurrentNode({ ...startingSource });
        }
      });
    } else if (!currentNode?.id) {
      dispatch(getProjectDetails(projectId)).then(() => {
        const startingSource = nodes.find((node) => node.is_start === true);
        const projectName = projectsList.find(
          (project) => project.id === projectId,
        );
        setProjectName(projectName?.name);
        setCurrentNode({ ...startingSource });
        setSource(startingSource?.video_file);
      });
    }
  }, [dispatch, nodes, projectsList, projectId]);

  useEffect(() => {
    if (currentNode.id) {
      if (JSON.parse(currentNode.matching)[0]) {
        setIsMatching(true);
      }
      // if (JSON.parse(currentNode.drag_and_drop_matching)[0]) {
      //   setIsDnD(true);
      // }
    }
  }, [currentNode]);

  const playVideo = (src) => {
    const savedTime = parseFloat(localStorage.getItem("videoTimestamp"));
    const video = myVideo.current;
    video.src = src;
    if (!isNaN(savedTime) && isRefreshed) {
      video.currentTime = savedTime;
      setIsRefreshed(false);
    }
    // Konva animation frame
    const animate = () => {
      if (video.paused || video.ended) {
        return;
      }
      videoNode?.getLayer().batchDraw();
      requestAnimationFrame(animate);
    };

    video.addEventListener("loadeddata", () => {
      video.play();
      animate();
    });
  };

  useEffect(() => {
    if (
      currentNode.id &&
      (!isMatching || !isDnD) &&
      myVideo.current
      // &&
      // canvas.current
    ) {
      // myVideo.current.src = currentNode.video_file;
      // myVideo.current.load();
      // myVideo.current.addEventListener("canplaythrough", () => {
      //   myVideo.current.play();
      // });
      // playCanvas();
      localStorage.setItem("currentNodeId", JSON.stringify(currentNode.id));
      playVideo(currentNode.video_file);
      if (!currentNode.hide_onscreen_question) {
        const choices = edges.filter((edge) => edge.source === currentNode?.id);
        setIsColorPresent(choices.some((edge) => JSON.parse(edge.colors)[0]));
      }
    }
    // return () => {
    // document
    //   .querySelectorAll("video")[0]
    //   .removeEventListener("ended", onEndOfVideo);
    // cancelAnimationFrame(playCanvas);
    // };
  }, [currentNode, edges, videoNode]);

  useEffect(() => {
    const getMedia = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        setMedia(stream);
      } catch (error) {
        dispatch(handleErrorMessage("Error accessing webcam"));
        console.error("Error accessing webcam", error);
      }
    };
    if (isCameraOn && currentNode.id && (!isDnD || !isMatching) && !media) {
      getMedia();
    }
  }, [isCameraOn, currentNode, isDnD, isMatching, media, dispatch]);

  // to preload videos
  const preloadedVideoLink = async (src) => {
    const res = await fetch(src);
    const blob = await res.blob();
    return URL.createObjectURL(blob);
  };

  const findNextNode = async (nextNodeId) => {
    const currentSource = nodes.find((node) => node.id === nextNodeId);
    const videoSource = currentSource.video_file;
    const updatedLink = await preloadedVideoLink(videoSource);
    dispatch(updateVideoLink(updatedLink, currentSource.id));
  };

  useEffect(() => {
    edges
      .filter((edge) => edge.source == currentNode?.id)
      .map((edge) => findNextNode(edge?.nextLinkChoice));
  }, [currentNode]);

  const sendRecordedBlob = useCallback(() => {
    const data = new FormData();
    data.append("file", recordedBlob.blob, "audio.wav");
    data.append("text", currentNode?.word_to_pronounce);
    dispatch(postUserResponse(data)).then(() => {
      const response = store.getState()?.user?.userResponse?.Response;
      const choice = edges
        .filter((edge) => edge.source === currentNode?.id)
        .find(
          (edge) =>
            edge?.choiceButtonText.toLowerCase() === response.toLowerCase(),
        );
      onClickingChoice(choice?.nextLinkChoice, choice?.choiceButtonText);
    });
  }, [currentNode, recordedBlob, edges, dispatch]);

  const onTimerCompletionWithoutChoice = () => {
    if (currentNode?.is_pronunciation) {
      setStartRecording(false);
      sendRecordedBlob();
    } else {
      setDisplayChoices(false);
      playVideo(currentNode.video_file);
    }
  };

  const onEndOfVideo = () => {
    if (currentNode?.hide_onscreen_question) {
      // if (!currentNode.is_start) {
      //   URL.revokeObjectURL(currentNode.video_file);
      // }
      const choice = edges.find((edge) => edge.source === currentNode?.id);
      const currentSource = nodes.find(
        (node) => node.id === choice?.nextLinkChoice,
      );
      setCurrentNode({ ...currentSource });
      setDisplayChoices(false);
    } else {
      setIsPronunciation(currentNode?.is_pronunciation);
      let flag = true;
      const choices = edges.filter((edge) => edge.source === currentNode?.id);
      for (let i = 0; i < choices.length; i++) {
        if (choices[i].is_no_response) {
          setDisplayChoices(false);
          onClickingChoice(choices[i]?.nextLinkChoice, `No Response`);
          flag = false;
          break;
        }
      }
      if (flag) {
        for (let i = 0; i < choices.length; i++) {
          const colorsArray = JSON.parse(choices[i].colors);
          if (colorsArray[0]) {
            setDisplayChoices(false);
            break;
          } else {
            setDisplayChoices(true);
          }
        }
      }
    }
    if (currentNode?.is_end) {
      window.location.href = "https://clapingo.com";
      localStorage.removeItem("currentNodeId");
      localStorage.removeItem("videoTimestamp");
    }
  };

  const onClickingChoice = (choiceId, choiceText) => {
    if (!currentNode.is_start) {
      URL.revokeObjectURL(currentNode.video_file);
    }
    const previousNode = currentNode?.id;
    const currentSource = nodes.filter((node) => node.id == choiceId)[0];
    setCurrentNode({ ...currentSource });
    const data = {
      current_user: currentUser,
      current_project: projectId,
      previous_node: previousNode,
      current_choice: choiceId,
      choice_value: choiceText,
    };
    setDisplayChoices(false);
    // if (
    // !JSON.parse(currentNode.matching)[0]
    //  &&
    // myVideo.current &&
    // canvas.current
    // !isMatching ||
    // !isDnD
    // ) {
    // playVideo(currentSource?.video_file);
    // myVideo.current.src = currentSource?.video_file;
    // myVideo.current.load();
    // myVideo.current.play();
    // }
    dispatch(storeUserResponse(data));
  };
  if (currentNode?.is_pronounciation && (!isMatching || !isDnD)) {
    if (!isMicOn) {
      dispatch(
        handleErrorMessage(
          "Please turn on your microphone for better experience.",
        ),
      );
    }
    window.setInterval(() => {
      let vid = myVideo.current;
      if (vid?.currentTime >= vid?.duration - 15) {
        setStartRecording(true);
      }
    }, 1000);
    window.clearInterval();
  }

  useEffect(() => {
    if (media) {
      videoRef.current.srcObject = media;
      videoRef.current.play();
    }
  }, [media]);

  function turnOnCamera() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          setMedia(stream);
        })
        .catch(function (error) {
          dispatch(handleErrorMessage("Error accessing webcam video"));
          console.error("Error accessing webcam video", error);
        });
    } else {
      dispatch(
        handleErrorMessage("getUserMedia is not supported by this browser"),
      );
      console.error("getUserMedia is not supported by this browser");
    }
  }
  // Function to turn off the webcam stream
  function turnOffCamera() {
    if (media) {
      media.getTracks().forEach((track) => track.stop());
      setMedia(null);
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    }
  }

  const endVideo = () => {
    localStorage.removeItem("currentNodeId");
    localStorage.removeItem("videoTimestamp");
    let tokenFromStorage = localStorage.getItem("UserDataToken");
    let userDataFromStorage = JSON.parse(localStorage.getItem("UserData"));
    turnOffCamera();
    if (userDataFromStorage?.email && tokenFromStorage) {
      navigate("/");
    } else {
      window.location.href = "https://clapingo.com";
    }
  };

  const handleTimeUpdate = (event) => {
    const newTime = event.target.currentTime;
    localStorage.setItem("videoTimestamp", newTime);
  };

  // useEffect(() => {
  //   const handleClick = (e) => {
  //     if (isColorPresent) {
  //       const x = e.pageX;
  //       const y = e.pageY;
  //       const colorData = getPixelColor(canvas, x, y);
  //       console.log(colorData.hex);
  //       if (colorData.hex) {
  //         const choices = edges.filter(
  //           (edge) => edge.source === currentNode?.id
  //         );
  //         for (let i = 0; i < choices.length; i++) {
  //           const colorsArray = JSON.parse(choices[i].colors);
  //           if (colorsArray.includes(colorData.hex)) {
  //             onClickingChoice(
  //               choices[i]?.nextLinkChoice,
  //               JSON.stringify(colorData.hex)
  //             );
  //             break;
  //           } else if (!colorsArray[0] && !choices[i].is_no_response) {
  //             onClickingChoice(
  //               choices[i]?.nextLinkChoice,
  //               `Wrong color ${colorData.hex} is picked`
  //             );
  //           }
  //         }
  //       }
  //     }
  //   };
  //   if (canvas.current) {
  //     canvas.current.addEventListener("click", handleClick);
  //   }
  //   return () => {
  //     if (canvas.current) {
  //       canvas.current.removeEventListener("click", handleClick);
  //     }
  //   };
  // }, [canvas, currentNode, edges, isColorPresent, onClickingChoice]);
  // function playCanvas() {
  //   const context = canvas.current.getContext("2d", { alpha: false });
  //   context.drawImage(
  //     myVideo.current,
  //     0,
  //     0,
  //     Number(window.innerWidth),
  //     Number(window.innerHeight)
  //   );
  //   requestAnimationFrame(playCanvas);
  // }
  // function getPixelColor(canvas, x, y) {
  //   var pixel = canvas.getContext("2d").getImageData(x, y, 1, 1).data;
  //   var r = pixel[0];
  //   var g = pixel[1];
  //   var b = pixel[2];
  //   var a = pixel[3] / 255;
  //   a = Math.round(a * 100) / 100;
  //   var rHex = r.toString(16);
  //   r < 16 && (rHex = "0" + rHex);
  //   var gHex = g.toString(16);
  //   g < 16 && (gHex = "0" + gHex);
  //   var bHex = b.toString(16);
  //   b < 16 && (bHex = "0" + bHex);
  //   var rgbColor = "rgb(" + r + "," + g + "," + b + ")";
  //   var hexColor = "#" + rHex + gHex + bHex;
  //   return {
  //     rgb: rgbColor,
  //     hex: hexColor,
  //   };
  // }
  return (
    <>
      {!currentNode.id ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!currentNode.id}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : isMatching ? (
        <MatchingPlayGround
          data={JSON.parse(currentNode?.matching)}
          currentNode={currentNode}
          onClickingChoice={onClickingChoice}
          edges={edges}
          setIsMatching={setIsMatching}
        />
      ) : isDnD ? (
        <DragAndDropPlayGround
          dragAndDrop={JSON.parse(currentNode?.drag_and_drop_matching)}
          currentNode={currentNode}
          onClickingChoice={onClickingChoice}
          edges={edges}
          setIsDnD={setIsDnD}
        />
      ) : (
        <div id="outerContainer">
          <ApiResponseAlert />
          <div style={{ position: "fixed", zIndex: -100 }}>
            <Stage width={window.innerWidth} height={window.innerHeight}>
              <Layer>
                <Image
                  ref={(node) => {
                    setVidoeNode(node);
                  }}
                  image={myVideo.current}
                  width={window.innerWidth}
                  height={window.innerHeight}
                />
              </Layer>
            </Stage>
          </div>
          <video
            ref={myVideo}
            hidden
            onEnded={onEndOfVideo}
            onTimeUpdate={handleTimeUpdate}
          />
          {/* {buffering && (
            <div
              style={{
                width: "100%",
                position: "absolute",
                top: "0",
                zIndex: "10",
              }}
            >
              <LinearProgress color="secondary" />
            </div>
          )}
          <canvas
            ref={canvas}
            id="cs"
            style={{ position: "fixed", zIndex: -100 }}
            width={Number(window.innerWidth)}
            height={Number(window.innerHeight)}
          ></canvas>
          <video
            hidden
            ref={myVideo}
            poster={currentNode?.image_file}
            className={styles.playScreenFrame}
            playsInline
            crossOrigin="*"
            preload="auto"
            id="myVideo"
            onEnded={onEndOfVideo}
            style={{ opacity: displayChoices ? 0.5 : 1 }}
            autoPlay
          >
            <source src={currentNode?.video_file} />
          </video> */}

          <div className={styles.webcamOuterContainer}>
            <div className={styles.webcamInnerContainer}>
              {isCameraOn ? (
                <>
                  <div className={styles.webcamInnestContainer}>
                    <video
                      ref={videoRef}
                      muted
                      className={styles.webcamVideo}
                    />
                    <div className={styles.infoBar}>
                      <span className={styles.info}></span>
                      <div className={styles.scoreContainer}>
                        <span className={styles.info}>x {score}</span>
                        <img src="/assets/Gold_Medal.svg" alt="badge" />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className={styles.isCameraOn}>
                  <img
                    src="/assets/Body.svg"
                    alt="Camera"
                    className={styles.bodyIcon}
                  />
                  <div className={styles.infoBar}>
                    <span className={styles.info}></span>
                    <div className={styles.scoreContainer}>
                      <span className={styles.info}>x {score}</span>
                      <img src="/assets/Gold_Medal.svg" alt="badge" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {currentNode?.is_pronounciation && startRecording && (
            <div style={{ display: "none" }}>
              <Recorder
                setRecordedBlob={(blob) => setRecordedBlob(blob)}
                sendRecordedBlob={sendRecordedBlob}
                timerDuration={6}
              />
            </div>
          )}

          {displayChoices &&
            !currentNode?.is_end &&
            !currentNode?.is_pronounciation && (
              <QuestionBox
                currentNode={currentNode}
                onClickingChoice={onClickingChoice}
                edges={edges}
                onTimerCompletionWithoutChoice={onTimerCompletionWithoutChoice}
                setScore={setScore}
              />
            )}
          <div className={styles.outerControlsContainer}>
            <div id={styles.controlsContainer}>
              <div className={styles.classTitle}>
                {projectName || "Financial Education"}
              </div>
              <div className={styles.iconsContainer}>
                {isCameraOn ? (
                  <span
                    className={styles.outerCirce}
                    onClick={() => {
                      turnOffCamera();
                      setIsCameraOn(!isCameraOn);
                    }}
                  >
                    <VideocamIcon style={{ margin: "10px" }} />
                  </span>
                ) : (
                  <span
                    className={styles.outerCirce}
                    onClick={() => {
                      turnOnCamera();
                      setIsCameraOn(!isCameraOn);
                    }}
                  >
                    <VideocamOffIcon style={{ margin: "10px" }} />
                  </span>
                )}
                {isMicOn ? (
                  <span
                    className={styles.outerCirce}
                    onClick={() => {
                      setIsMicOn(!isMicOn);
                    }}
                  >
                    <MicIcon style={{ margin: "10px" }} />
                  </span>
                ) : (
                  <span
                    className={styles.outerCirce}
                    onClick={() => {
                      setIsMicOn(!isMicOn);
                    }}
                  >
                    <MicOffIcon style={{ margin: "10px" }} />
                  </span>
                )}
                <button className={styles.endButton} onClick={() => endVideo()}>
                  <div className={styles.btnInnerContainer}>
                    <PhoneDisabledIcon className={styles.endClassIcon} />
                    End Class
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PlayScreen;
