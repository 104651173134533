import React, { useEffect, useState } from "react";
import styles from "../css/playScreen.module.css";

const QuestionBox = ({
  currentNode,
  onClickingChoice,
  edges,
  onTimerCompletionWithoutChoice,
  setScore,
}) => {
  const timer = currentNode?.display_time > 0 ? currentNode?.display_time : 30;
  const [seconds, setSeconds] = useState(timer);
  const audio = new Audio("/Audio/button-sound.wav");
  const handleClick = (score) => {
    audio.currentTime = 0;
    audio.play();
    setScore((prev) => prev + score);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      onTimerCompletionWithoutChoice();
    }
  }, [seconds]);
  return (
    <div className={styles.questionBoxContainer}>
      <div className={styles.questionBoxInnerContainer}>
        <div className={styles.question}>{currentNode?.onscreen_question}</div>
        <div className={styles.optionAndTimerContainer}>
          <div className={styles.leftQuestionBoxContainer}>
            {edges
              .filter((edge) => edge.source == currentNode.id)
              .map((edge) => {
                return (
                  <div
                    key={edge.id}
                    className={styles.option}
                    onClick={() => {
                      handleClick(edge.score);
                      onClickingChoice(
                        edge?.nextLinkChoice,
                        edge.choiceButtonText,
                      );
                    }}
                  >
                    {edge.choiceButtonText}
                  </div>
                );
              })}
          </div>
          <div className={styles.rightQuestionBoxContainer}>
            <div className={styles.timerContainer}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <img src="/Timer-big.svg" alt="timer" />
                <div className={styles.timeContainer}>
                  <div className={styles.time}>{seconds}</div>
                  <div className={styles.sec}>sec</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionBox;
