import { React, useEffect, useRef, useState } from "react";
import styles1 from "../css/signup.module.css";
import styles from "../css/newProjectForm.module.css";
import axios from "axios";
import ApiResponseAlert from "./ApiResponseAlert";
import { handleErrorMessage } from "../redux/actions/projects";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../redux/actions/user";

export default function Signin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const token = useSelector((state) => state?.user?.token);
  const errorMessage = useSelector((state) => state?.apiResponse?.errorMsg);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Handling the email change
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  // Handling the password change
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  // useEffect(() => {
  //     const tokenFromLocalStorage = localStorage.getItem('UserDataToken');
  //     if(tokenFromLocalStorage){
  //         const userDataFromStorage = localStorage.getItem("UserData");
  //     if(userDataFromStorage && userDataFromStorage?.email){
  //         const data = {
  //             username:userDataFromStorage?.email,password:userDataFromStorage?.password
  //         }
  //         dispatch(loginUser(data)).then(()=>{if(token) navigate('/')});
  //     }
  // };
  //   },[]);

  // Handling the form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      dispatch(handleErrorMessage("Please enter both the fields"));
    } else {
      const data = {
        username: email,
        password,
      };
      console.log();
      dispatch(loginUser(data)).then(() => {
        if (errorMessage === "") {
          navigate("/");
        }
      });
    }
  };
  return (
    <div className={styles1.outerFormContainerSignIn}>
      <div className={styles1.signinFormContainer}>
        <div>
          <h1>Sign in</h1>
        </div>
        <hr className={styles.NewProjectHr} />
        <ApiResponseAlert />
        <form>
          <label>
            <b>Email</b>
          </label>
          <input
            onChange={handleEmail}
            className={styles.descriptionInput}
            placeholder={"Enter Email"}
            value={email}
            type="email"
          />

          <label>
            <b>Password</b>
          </label>
          <input
            onChange={handlePassword}
            className={styles.descriptionInput}
            placeholder={"Enter Password"}
            value={password}
            type="password"
          />
          <hr className={styles.NewProjectHr} />
          <button
            onClick={handleSubmit}
            className={styles.submitButton}
            type="submit"
          >
            Submit
          </button>
        </form>
        <div className={styles1.separator}>or</div>
        <div className={styles1.bottomSigninText}>
          Don't have an account?
          <span onClick={() => navigate("/signup")}>
            <b style={{ cursor: "pointer" }}> Sign up</b>
          </span>
        </div>
      </div>
    </div>
  );
}
