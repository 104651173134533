import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "../css/projectList.module.css";
import { getProjects, deleteProject } from "../redux/actions/projects";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export default function ProjectsList() {
  const projectsList = useSelector((state) => state.projects?.projects);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getProjects());
  }, []);
  const openProject = (id) => {
    navigate(`/project/${id}`);
  };
  const onDeleteProject = (projectId) => {
    var result = window.confirm("Are you sure to delete?");
    if (result) {
      dispatch(deleteProject(projectId));
    }
  };
  const onPlayProject = (id) => {
    navigate(`/joiningscreen/${id}/6f96dc6b798807a3792d0bee126a494ab97d38d3`);
    // window.open(`/play/${id}`, "_blank");
  };
  return (
    <div className={styles.projectsListContainer}>
      <h1 style={{ paddingLeft: 10 }}>List of Projects</h1>
      <div className={styles.projectsList}>
        {projectsList.map((project) => (
          <div key={project.id}>
            <hr className={styles.projectHr} />
            <div className={styles.projectContainer}>
              <div>
                <h4>{project.name}</h4>
                <p style={{ marginTop: 4 }}>{project.description}</p>
              </div>
              <div className={styles.projectPrivContainer}>
                <button
                  className={styles.projectPriv}
                  onClick={() => openProject(project.id)}
                >
                  <ModeEditIcon
                    sx={{ color: "white" }}
                    style={{ height: 18, width: 18, marginRight: 5 }}
                  />
                  <b>Edit</b>
                </button>
                <button
                  className={styles.projectPriv}
                  onClick={() => onDeleteProject(project.id)}
                >
                  <DeleteForeverIcon
                    sx={{ color: "white" }}
                    style={{ height: 18, width: 18, marginRight: 5 }}
                  />
                  <b>Delete</b>
                </button>
                <button
                  className={styles.projectPriv}
                  onClick={() => onPlayProject(project.id)}
                >
                  <PlayCircleFilledWhiteIcon
                    sx={{ color: "white" }}
                    style={{ height: 18, width: 18, marginRight: 5 }}
                  />
                  <b>Play</b>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
