import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../css/Header.module.css";
import HomeIcon from "@mui/icons-material/Home";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { LogoutOutlined } from "@mui/icons-material";
import { logoutUser } from "../redux/actions/user";
import { useDispatch } from "react-redux";

export default function Header({ addNode }) {
  const navigate = useNavigate();
  const projectId = window.location.pathname.split("/").pop();
  const dispatch = useDispatch();
  return (
    <div className={styles.headerContainer}>
      <div onClick={() => navigate("/")} className={styles.headerText}>
        <HomeIcon style={{ marginRight: 5 }} />
        Home
      </div>
      <div
        className={styles.headerText}
        onClick={() =>
          navigate(
            `/joiningscreen/${projectId}/6f96dc6b798807a3792d0bee126a494ab97d38d3`,
          )
        }
      >
        <PlayCircleOutlineIcon style={{ marginRight: 5 }} />
        Play
      </div>
      <div onClick={() => addNode()} className={styles.headerText}>
        <AddCircleOutlineIcon style={{ marginRight: 5 }} />
        Add Node
      </div>
      <div
        onClick={() => dispatch(logoutUser()).then(() => navigate("/signin"))}
        className={styles.headerText}
        style={{ Self: "flex-end" }}
      >
        <LogoutOutlined style={{ marginRight: 5 }} />
        Log Out
      </div>
    </div>
  );
}
