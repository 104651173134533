import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import styles from "../css/nodeDetailsForm.module.css";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormControl, InputLabel } from "@mui/material";
import { useDispatch } from "react-redux";
import { handleErrorMessage } from "../redux/actions/projects";
import ChooseImage from "./ChooseImage";
import S3FileUpload from "react-s3/lib/ReactS3";
import LoadingButton from "@mui/lab/LoadingButton";
window.Buffer = window.Buffer || require("buffer").Buffer;

const DragAndDrop = ({
  nodes,
  edges,
  setChoices,
  handleSubmit,
  dragAndDrop,
  setDragAndDrop,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [dummyChoices, setDummyChoices] = useState([]);

  useEffect(() => {
    if (edges.length === 2 && dragAndDrop.length > 0) {
      setDummyChoices([...edges]);
      setLoading(false);
    } else {
      setDummyChoices([
        {
          choiceNumber: 1,
          choiceButtonText: "",
          choiceButtonBackgroundColor: "#000000",
          colors: "[]",
          choiceButtonTextColor: "#ffffff",
          nextLinkChoice: "",
          is_no_response: false,
        },
        {
          choiceNumber: 2,
          choiceButtonText: "",
          choiceButtonBackgroundColor: "#000000",
          colors: "[]",
          choiceButtonTextColor: "#ffffff",
          nextLinkChoice: "",
          is_no_response: false,
        },
      ]);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      if (
        dummyChoices[0].nextLinkChoice !== "" &&
        dummyChoices[1].nextLinkChoice !== ""
      )
        setChoices(dummyChoices);
    }
  }, [dummyChoices, loading]);

  const config = {
    bucketName: "clapngobucket",
    dirName: "branching/drag_and_drop",
    region: "us-east-1",
    accessKeyId: `${process.env.REACT_APP_ACCESS_KEY}`,
    secretAccessKey: `${process.env.REACT_APP_SECRET_KEY}`,
  };
  const validateData = (e) => {
    let error = false;
    if (
      dummyChoices[0].nextLinkChoice === "" ||
      dummyChoices[1].nextLinkChoice === ""
    ) {
      error = true;
      dispatch(handleErrorMessage("Please select all choices"));
      return;
    }

    if (dragAndDrop.length > 1) {
      for (let i = 0; i < dragAndDrop.length; i++) {
        if (!dragAndDrop[i].imageUrl || !dragAndDrop[i].objectName) {
          console.log(dragAndDrop[i].imageUrl, dragAndDrop[i].objectName);
          error = true;
          dispatch(
            handleErrorMessage(`Please fill all data before submitting`),
          );
          return;
        }
      }
    }
    if (
      dragAndDrop.length === 1 &&
      dragAndDrop[0].imageUrl &&
      dragAndDrop[0].objectName
    ) {
      error = true;
      dispatch(handleErrorMessage("Please add atleast 4-5 rows"));
      return;
    }
    if (
      dragAndDrop.length === 1 &&
      (!dragAndDrop[0].imageUrl || !dragAndDrop[0].objectName)
    )
      error = false;

    if (!error) {
      if (dragAndDrop.length > 1) {
        dragAndDrop.map((data, index) => {
          if (!isValidUrl(data.imageUrl)) {
            fetch(data.imageUrl)
              .then((res) => res.blob())
              .then((blob) => {
                const file = new File([blob], data.fileName, {
                  type: data.mimeType,
                });
                S3FileUpload.uploadFile(file, config)
                  .then((data) => {
                    const awsUrl = data.location;
                    changeColumnData(index, { imageUrl: awsUrl });
                  })
                  .catch((err) => {
                    dispatch(
                      handleErrorMessage(
                        `An error occurred while uploading ${file.name}`,
                      ),
                    );
                    console.log(err);
                  });
              });
          }
        });
      }
      handleSubmit(e);
    }
  };
  function isValidUrl(str) {
    // Regular expression pattern to match URLs
    var urlPattern = /^https:\/\//;

    // Test the input string against the pattern
    return urlPattern.test(str);
  }
  const addMoreRows = () => {
    setDragAndDrop([
      ...dragAndDrop,
      {
        imageUrl: "",
        objectName: "",
      },
    ]);
  };

  const handleDelete = (index) => {
    const newData = [...dragAndDrop];
    newData.splice(index, 1);
    setDragAndDrop(newData);
    if (!newData.length) {
      setDragAndDrop([
        {
          imageUrl: "",
          objectName: "",
        },
      ]);
    }
  };

  const changeChoiceData = (choiceNumber, keyName, keyValue) => {
    const newChoiceData = { ...dummyChoices[choiceNumber - 1] };
    newChoiceData[keyName] = keyValue;
    const newChoices = dummyChoices.map((choice) =>
      choice.choiceNumber === choiceNumber ? newChoiceData : choice,
    );
    setDummyChoices(newChoices);
  };

  const changeColumnData = (jsonIndexNumber, object) => {
    let keys = Object.keys(object);
    let values = Object.values(object);

    const updatedJson = [...dragAndDrop];
    keys.map((data, index) => {
      updatedJson[jsonIndexNumber][data] = values[index];
    });
    setDragAndDrop(updatedJson);
  };
  // useEffect(() => console.log(dragAndDrop), [dragAndDrop]);
  return (
    <>
      <h4>Create Drag and Drop</h4>
      <div className={styles.matchingOuterContainer}>
        <div className={styles.innerColumn}>
          <h4 style={{ textAlign: "center" }}>Draggable</h4>
          <Divider />
          <div style={{ padding: "10px" }}>
            {dragAndDrop.map((data, index) => (
              <div style={{ display: "flex" }} key={index}>
                <span style={{ marginRight: "10px" }}>
                  <b>{index + 1}.</b>
                </span>
                <ChooseImage
                  imageUrl={data.imageUrl}
                  objectName={data.objectName}
                  changeColumnData={changeColumnData}
                  index={index}
                />
              </div>
            ))}
          </div>
        </div>
        <Divider orientation="vertical" flexItem />
        <div className={styles.innerColumn}>
          <h4 style={{ textAlign: "center" }}>Droppable Name</h4>
          <Divider />
          <div style={{ padding: "10px" }}>
            {dragAndDrop.map((data, index) => (
              <div
                style={{
                  display: "flex",
                  height: "180px",
                  alignItems: "flex-start",
                }}
                key={index}
              >
                <span style={{ marginRight: "10px" }}>
                  <b>{index + 1}.</b>
                </span>
                <input
                  id={`column${index}`}
                  name={`column${index}`}
                  className={styles.DnDColumnInput}
                  type="text"
                  onChange={(e) =>
                    changeColumnData(index, { objectName: e.target.value })
                  }
                  value={data.objectName}
                />
                <DeleteIcon
                  onClick={() => handleDelete(index)}
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <button onClick={addMoreRows} className={styles.submitButton}>
          Add Row
        </button>
      </div>
      <h4>For All Correct Answer</h4>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Links to</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={dummyChoices[0]?.nextLinkChoice}
              label="Links to"
              onChange={(e) =>
                changeChoiceData(1, "nextLinkChoice", e.target.value)
              }
            >
              {nodes.map((node) => (
                <MenuItem key={node.id} value={node.id}>
                  {node.id}-{node.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <h4>For Incorrect Answer</h4>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Links to</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={dummyChoices[1]?.nextLinkChoice}
              label="Links to"
              onChange={(e) =>
                changeChoiceData(2, "nextLinkChoice", e.target.value)
              }
            >
              {nodes.map((node) => (
                <MenuItem key={node.id} value={node.id}>
                  {node.id}-{node.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
      <LoadingButton
        loading={false}
        variant="outlined"
        onClick={(e) => {
          validateData(e);
        }}
        sx={{ my: 2 }}
      >
        Submit
      </LoadingButton>
    </>
  );
};

export default DragAndDrop;
