import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import styles from "../css/nodeDetailsForm.module.css";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormControl, InputLabel } from "@mui/material";
import { useDispatch } from "react-redux";
import { handleErrorMessage } from "../redux/actions/projects";

const Matching = ({
  nodes,
  edges,
  setChoices,
  handleSubmit,
  matchingData,
  setMatchingData,
}) => {
  // const matchingData = [
  // {
  //   columnNumber: 0,
  //   column1Data: "",
  //   column2Data: "",
  // },
  // {
  //   columnNumber: 1,
  //   column1Data: "",
  //   column2Data: "",
  // },
  // {
  //   columnNumber: 2,
  //   column1Data: "",
  //   column2Data: "",
  // },
  // {
  //   columnNumber: 3,
  //   column1Data: "",
  //   column2Data: "",
  // },
  // {
  //   columnNumber: 4,
  //   column1Data: "",
  //   column2Data: "",
  // },
  // ];
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [dummyChoices, setDummyChoices] = useState([]);
  const [columnNumber, setColumnNumber] = useState(
    matchingData.length === 0 ? 0 : matchingData.length - 1,
  );

  useEffect(() => {
    if (edges.length === 2 && matchingData.length > 0) {
      setDummyChoices([...edges]);
      setLoading(false);
    } else {
      setDummyChoices([
        {
          choiceNumber: 1,
          choiceButtonText: "",
          choiceButtonBackgroundColor: "#000000",
          colors: "[]",
          choiceButtonTextColor: "#ffffff",
          nextLinkChoice: "",
          is_no_response: false,
        },
        {
          choiceNumber: 2,
          choiceButtonText: "",
          choiceButtonBackgroundColor: "#000000",
          colors: "[]",
          choiceButtonTextColor: "#ffffff",
          nextLinkChoice: "",
          is_no_response: false,
        },
      ]);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      if (
        dummyChoices[0].nextLinkChoice !== "" &&
        dummyChoices[0].nextLinkChoice !== ""
      )
        setChoices(dummyChoices);
    }
  }, [dummyChoices, loading]);

  const validateData = (e) => {
    let error = false;
    if (
      dummyChoices[0].nextLinkChoice === "" ||
      dummyChoices[1].nextLinkChoice === ""
    ) {
      error = true;
      dispatch(handleErrorMessage("Please select all choices"));
    }
    if (!error) {
      handleSubmit(e);
    }
  };
  const addMoreRows = () => {
    setMatchingData([
      ...matchingData,
      {
        columnNumber: columnNumber + 1,
        column1Data: "",
        column2Data: "",
      },
    ]);
    setColumnNumber(columnNumber + 1);
  };

  useEffect(() => {
    for (let i = 0; i < matchingData.length; i++) {
      matchingData[i].columnNumber = i;
    }
  }, [matchingData]);

  const handleDelete = (index) => {
    const newData = [...matchingData];
    newData.splice(index, 1);
    setMatchingData(newData);
    setColumnNumber(columnNumber >= 1 ? columnNumber - 1 : 0);
    if (!newData.length) {
      setMatchingData([
        {
          columnNumber: 0,
          column1Data: "",
          column2Data: "",
        },
      ]);
    }
  };
  const changeChoiceData = (choiceNumber, keyName, keyValue) => {
    const newChoiceData = { ...dummyChoices[choiceNumber - 1] };
    newChoiceData[keyName] = keyValue;
    const newChoices = dummyChoices.map((choice) =>
      choice.choiceNumber === choiceNumber ? newChoiceData : choice,
    );
    setDummyChoices(newChoices);
  };
  const changeColumnData = (columnIndexNumber, keyName, keyValue) => {
    const newColumnData = { ...matchingData[columnIndexNumber] };
    newColumnData[keyName] = keyValue;
    const newColumns = matchingData.map((column) =>
      column.columnNumber === columnIndexNumber ? newColumnData : column,
    );
    setMatchingData(newColumns);
  };
  return (
    <>
      <h4>Create Matching</h4>
      <div className={styles.matchingOuterContainer}>
        <div className={styles.innerColumn}>
          <h4 style={{ textAlign: "center" }}>Column 1</h4>
          <Divider />
          <div style={{ padding: "10px" }}>
            {matchingData.map((data, index) => (
              <div style={{ display: "flex" }} key={index}>
                <span style={{ marginRight: "10px" }}>
                  <b>{index + 1}.</b>
                </span>
                <input
                  id="column"
                  className={styles.columnInput}
                  type="text"
                  key={index}
                  onChange={(e) =>
                    changeColumnData(index, "column1Data", e.target.value)
                  }
                  value={data.column1Data}
                />
              </div>
            ))}
          </div>
        </div>
        <Divider orientation="vertical" flexItem />
        <div className={styles.innerColumn}>
          <h4 style={{ textAlign: "center" }}>Column 2</h4>
          <Divider />
          <div style={{ padding: "10px" }}>
            {matchingData.map((data, index) => (
              <div style={{ display: "flex" }} key={index}>
                <input
                  id={`column${index}`}
                  name={`column${index}`}
                  className={styles.columnInput}
                  type="text"
                  key={index}
                  onChange={(e) =>
                    changeColumnData(index, "column2Data", e.target.value)
                  }
                  value={data.column2Data}
                />
                <DeleteIcon
                  onClick={() => handleDelete(index)}
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <button onClick={addMoreRows} className={styles.submitButton}>
          Add Row
        </button>
      </div>
      <h4>For All Correct Answer</h4>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Links to</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={dummyChoices[0]?.nextLinkChoice}
              label="Links to"
              onChange={(e) =>
                changeChoiceData(1, "nextLinkChoice", e.target.value)
              }
            >
              {nodes.map((node) => (
                <MenuItem key={node.id} value={node.id}>
                  {node.id}-{node.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <h4>For Incorrect Answer</h4>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Links to</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={dummyChoices[1]?.nextLinkChoice}
              label="Links to"
              onChange={(e) =>
                changeChoiceData(2, "nextLinkChoice", e.target.value)
              }
            >
              {nodes.map((node) => (
                <MenuItem key={node.id} value={node.id}>
                  {node.id}-{node.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
      <button
        onClick={(e) => {
          validateData(e);
        }}
        className={styles.submitButton}
      >
        Submit
      </button>
    </>
  );
};

export default Matching;
