import axios from "axios";
import { store } from "../store";
import { errorMessageFromResponse } from "../../commonFunctions";
import {
  CREATE_PROJECT,
  GET_ERROR_MESSAGE,
  GET_PROJECTS,
  GET_SUCCESS_MESSAGE,
  DELETE_PROJECT,
} from "../constants/actionTypes";
import {
  CREATE_PROJECT_API,
  GET_PROJECTS_API,
  DELETE_PROJECT_API,
} from "../constants/apiEndpoints";

export const handleErrorMessage = (error) => {
  const errorMessage = errorMessageFromResponse(error);
  return {
    type: GET_ERROR_MESSAGE,
    payload: errorMessage,
  };
};
export const handleSuccessMessage = (successMessage) => {
  return {
    type: GET_SUCCESS_MESSAGE,
    payload: successMessage,
  };
};
export const getProjects = () => {
  return async (dispatch) => {
    const token = store.getState()?.user?.token;
    await axios({
      method: "get",
      url: GET_PROJECTS_API,
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.status) {
          dispatch({
            type: GET_PROJECTS,
            payload: response.data?.data,
          });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(error));
        return error;
      });
  };
};

export const createProject = (data) => {
  return async (dispatch) => {
    const token = store.getState()?.user?.token;
    await axios({
      method: "post",
      url: CREATE_PROJECT_API,
      data: data,
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status) {
          dispatch({
            type: CREATE_PROJECT,
            payload: response.data?.data,
          });
          dispatch(
            handleSuccessMessage("The Project is created successfully!"),
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(error));
        return error;
      });
  };
};
export const deleteProject = (projectId) => {
  const token = store.getState()?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "delete",
      url: `${DELETE_PROJECT_API}${projectId}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.status >= 200) {
          dispatch({
            type: DELETE_PROJECT,
            payload: projectId.toString(),
          });
          dispatch(handleSuccessMessage("Project Deleted Successfully!"));
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(error));
        return error;
      });
  };
};
