import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "../css/nodeDetailsForm.module.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ChoiceDetailForm from "./ChoiceDetailForm";
import { deleteEdge, updateNode } from "../redux/actions/nodes";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VideocamIcon from "@mui/icons-material/Videocam";
import {
  handleErrorMessage,
  handleSuccessMessage,
} from "../redux/actions/projects";
import Matching from "./Matching";
import { LinearProgress } from "@mui/material";
import DragAndDrop from "./DragAndDrop";
// import AWS, { S3 } from "aws-sdk";
import S3FileUpload from "react-s3/lib/ReactS3";
import CircularProgressWithLabel from "./CircularProgressWithLabel";

export default function NodeDetailsForm({
  projectId,
  nodeData,
  nodes,
  edges,
  onElementsRemove,
  selectedNodeDataPosition,
}) {
  const nodeNameRef = useRef("");
  const descriptionRef = useRef("");
  const [imageUrl, setImageUrl] = useState(nodeData?.image_file || "");
  const [image, setImage] = useState(nodeData?.image_file || null);
  const [videoUrl, setVideoUrl] = useState(nodeData?.video_file || "");
  const onScreenQuestion = useRef("");
  const wordToPronounce = useRef("");
  const hideQuestion = useRef();
  const isEndNode = useRef();
  const isStartNode = useRef();
  const isPronounciation = useRef();
  const shouldShowTimer = useRef(false);
  const [choiceValue, setValue] = useState(0);
  const [value, setTabValue] = useState(0);
  const displayTime = useRef(0);
  const [choices, setChoices] = useState(
    edges.length > 0
      ? [...edges]
      : [
          {
            choiceNumber: 1,
            choiceButtonText: "",
            choiceButtonBackgroundColor: "#000000",
            colors: "[]",
            choiceButtonTextColor: "#ffffff",
            nextLinkChoice: "",
            is_no_response: false,
            score: 0,
          },
        ],
  );
  const [matchingData, setMatchingData] = useState([]);
  const [dragAndDrop, setDragAndDrop] = useState([
    {
      imageUrl: "",
      objectName: "",
    },
  ]);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (nodeData) {
      nodeNameRef.current.value = nodeData?.name || "";
      descriptionRef.current.value = nodeData?.description || "";
      onScreenQuestion.current.value = nodeData?.onscreen_question || "";
      hideQuestion.current.checked = nodeData?.hide_onscreen_question || false;
      isStartNode.current.checked = nodeData?.is_start || false;
      isEndNode.current.checked = nodeData?.is_end || false;
      shouldShowTimer.current.checked = nodeData?.show_timer || false;
      isPronounciation.current.checked = nodeData?.is_pronounciation || false;
      displayTime.current.value = nodeData?.display_time || 0;
      wordToPronounce.current.value = nodeData?.word_to_pronounce || "";
      setMatchingData(
        JSON.parse(nodeData?.matching).length > 0
          ? JSON.parse(nodeData?.matching)
          : [
              {
                columnNumber: 0,
                column1Data: "",
                column2Data: "",
              },
            ],
      );
      setDragAndDrop(
        nodeData.drag_and_drop_matching === ""
          ? [
              {
                imageUrl: "",
                objectName: "",
              },
            ]
          : JSON.parse(nodeData?.drag_and_drop_matching).length > 0
          ? JSON.parse(nodeData?.drag_and_drop_matching)
          : [
              {
                imageUrl: "",
                objectName: "",
              },
            ],
      );
      setLoading(false);
    }
  }, []);

  const changeChoiceData = (choiceNumber, keyName, keyValue) => {
    const newChoiceData = { ...choices[choiceNumber - 1] };
    newChoiceData[keyName] = keyValue;
    const newChoices = choices.map((choice) =>
      choice.choiceNumber === choiceNumber ? newChoiceData : choice,
    );
    setChoices(newChoices);
  };

  const onPicInput = (e) => {
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  // const spacesEndpoint = new AWS.Endpoint("sgp1.digitaloceanspaces.com");
  // const s3 = new AWS.S3({
  //   endpoint: spacesEndpoint,
  //   accessKeyId: "DO00HBF92GTJNB3MTGBC",
  //   secretAccessKey: "HcSKyb8u41py3YEDnQc09hurZNAb8UFy0WiFQtWgjtQ",
  // });
  // const uploadFile = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     let fileName = file.name.replace(/\s/g, "");
  //     const params = {
  //       Bucket: "clapingo-ai",
  //       Key: `video-ai/${fileName}`,
  //       Body: file,
  //       ACL: "public-read",
  //     };

  //     s3.upload(params)
  //       .on("httpUploadProgress", (progress) => {
  //         const percentUploaded = Math.round(
  //           (progress.loaded / progress.total) * 100
  //         );
  //         setProgress(percentUploaded);
  //       })
  //       .send((error, data) => {
  //         if (error) {
  //           console.log(error);
  //         } else {
  //           dispatch(handleSuccessMessage("Video uploaded successfully!"));
  //           console.log("File uploaded successfully!", data);
  //           if (isValidUrl(data.Location)) {
  //             setVideoUrl(data.Location);
  //           } else {
  //             setVideoUrl(`https://${data.Location}`);
  //           }
  //         }
  //       });
  //   }
  // };

  const uploadFile = (e) => {
    const file = e.target.files[0];
    const uniqueIdentifier = Date.now(); // Using timestamp
    const fileExtension = file.name.split(".").pop();
    const newFileName =
      file.name.replace("." + fileExtension, "") +
      "_" +
      uniqueIdentifier +
      "." +
      fileExtension;
    const newFile = new File([file], newFileName, { type: file.type });
    const config = {
      bucketName: "vidoes-ai",
      region: "ap-south-1",
      accessKeyId: `${process.env.REACT_APP_ACCESS_KEY}`,
      secretAccessKey: `${process.env.REACT_APP_SECRET_KEY}`,
    };
    if (file) {
      // console.log(newFile);
      S3FileUpload.uploadFile(newFile, config)
        .then((data) => {
          // console.log("File uploaded successfully!", data);
          dispatch(
            handleSuccessMessage(
              "Video uploaded successfully, please save your changes!",
            ),
          );
          if (isValidUrl(data.location)) {
            setVideoUrl(data.location);
          } else {
            setVideoUrl(`https://${data.location}`);
          }
        })
        .catch((err) => {
          dispatch(
            handleErrorMessage(
              `An error occurred while uploading ${file.name}`,
            ),
          );
          console.log(err);
        });
    }
  };

  function isValidUrl(str) {
    // Regular expression pattern to match URLs
    var urlPattern = /^https:\/\//;

    // Test the input string against the pattern
    return urlPattern.test(str);
  }

  const handleChange = (event, newValue) => {
    if (newValue < choices.length) {
      setValue(newValue);
    }
    //check of number of nodes for max choices
    else if (newValue < nodes.length) {
      setChoices([
        ...choices,
        {
          choiceNumber: newValue + 1,
          choiceButtonText: "",
          choiceButtonBackgroundColor: "#000000",
          colors: "[]",
          choiceButtonTextColor: "#ffffff",
          nextLinkChoice: "",
          is_no_response: false,
        },
      ]);
      setValue(newValue);
    } else {
      dispatch(handleErrorMessage("Cannot add choices more than nodes"));
    }
  };

  const handleTabs = (event, val) => {
    setTabValue(val);
  };

  const onDeleteChoice = (choiceData) => {
    const { id, choiceNumber } = choiceData;
    if (id) {
      dispatch(deleteEdge(id));
    }
    const newChoiceData = choices
      .filter((choice) => choice.choiceNumber !== choiceNumber)
      .map((choice, index) => ({
        ...choice,
        choiceNumber: index + 1,
      }));
    if (newChoiceData.length === 0) {
      setChoices([
        {
          choiceNumber: 1,
          choiceButtonText: "",
          choiceButtonBackgroundColor: "#000000",
          colors: "[]",
          choiceButtonTextColor: "#ffffff",
          nextLinkChoice: "",
          is_no_response: false,
        },
      ]);
    } else {
      setChoices(newChoiceData);
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    const choicesData = choices.reduce((reducer, choice) => {
      const {
        id,
        choiceButtonText,
        choiceButtonBackgroundColor,
        choiceButtonTextColor,
        nextLinkChoice,
        colors,
        is_no_response,
        score,
      } = choice;
      if (choice.nextLinkChoice != "") {
        reducer = [
          ...reducer,
          {
            id: id,
            button_background_color: choiceButtonBackgroundColor,
            button_text_color: choiceButtonTextColor,
            colors: colors,
            button_text: choiceButtonText,
            show_button_text: true,
            next_link: nextLinkChoice,
            associated_node: nodeData?.id,
            is_no_response: is_no_response,
            score: score,
          },
        ];
      }
      return reducer;
    }, []);
    const data = new FormData();
    data.append("id", nodeData?.id);
    data.append("name", nodeNameRef.current.value);
    data.append("description", descriptionRef.current.value);
    data.append("associated_project", projectId);
    data.append("show_timer", shouldShowTimer.current.checked);
    data.append("choices", JSON.stringify(choicesData));
    data.append("display_time", displayTime.current.value);
    data.append("onscreen_question", onScreenQuestion.current.value);
    data.append("hide_onscreen_question", hideQuestion.current.checked);
    data.append("is_start", isStartNode.current.checked);
    data.append("is_end", isEndNode.current.checked);
    data.append("position_x", selectedNodeDataPosition?.x);
    data.append("word_to_pronounce", wordToPronounce.current.value);
    data.append("position_y", selectedNodeDataPosition?.y);
    data.append("is_pronounciation", isPronounciation.current.checked);
    if (
      matchingData.length === 1 &&
      (!matchingData[0].column1Data || !matchingData[0].column2Data)
    ) {
      data.append("matching", "[]");
    } else {
      data.append("matching", JSON.stringify(matchingData));
    }

    if (
      dragAndDrop.length === 1 &&
      (!dragAndDrop[0].imageUrl || !dragAndDrop[0].objectName)
    ) {
      data.append("drag_and_drop_matching", "[]");
    } else {
      data.append("drag_and_drop_matching", JSON.stringify(dragAndDrop));
    }

    if (videoUrl !== "" && videoUrl != nodeData?.video_file) {
      data.append("video_file", videoUrl);
    }
    if (imageUrl !== "" && imageUrl != nodeData?.image_file) {
      data.append("image_file", image, image?.name);
    }
    dispatch(updateNode(data, nodeData?.id)).then(() => {});
  }
  // useKeypress("Control", () => {
  //   alert("you pressed escape!");
  // });
  // useEffect(() => {
  //   console.log(choices);
  // }, [choices]);
  return (
    <div className={styles.outerNodeFormContainer}>
      <Tabs value={value} onChange={handleTabs} aria-label="tabs">
        <Tab label="Nodes" />
        <Tab label="Matching" />
        <Tab label="Drag and drop" />
      </Tabs>
      <div style={{ display: value == 0 ? "block" : "none" }}>
        <div className={styles.deleteButton}>
          <DeleteForeverIcon
            onClick={() => onElementsRemove(nodeData?.id)}
            style={{ cursor: "pointer" }}
          />
        </div>
        <form>
          <div>
            <label htmlFor="name">
              <b>Name</b>
            </label>
            <input
              className={styles.descriptionInput}
              id="name"
              type="text"
              ref={nodeNameRef}
              placeholder={nodeNameRef?.current.value}
            />
          </div>
          <div>
            <label htmlFor="description">
              <b>Description</b>
            </label>
            <textarea
              className={styles.descriptionInput}
              id="description"
              ref={descriptionRef}
            />
            <div>
              <input
                type="checkbox"
                id="is_pronounciation"
                name="is_pronounciation"
                ref={isPronounciation}
              />
              <label htmlFor="is_pronounciation" style={{ marginLeft: 10 }}>
                <b>Is Pronounciation?</b>
              </label>
              <br />
              <br />
              <label htmlFor="word_to_pronounce">
                <b>Word To Pronounce</b>
              </label>
              <input
                className={styles.descriptionInput}
                id="word_to_pronounce"
                type="text"
                ref={wordToPronounce}
                placeholder={wordToPronounce?.current.value}
              />
            </div>
          </div>
          <hr className={styles.NewProjectHr} />
          <div>
            <label htmlFor="description">
              <b>OnScreen Question</b>
            </label>
            <textarea
              className={styles.descriptionInput}
              id="description"
              ref={onScreenQuestion}
              placeholder={onScreenQuestion?.current.value}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <input
                  type="checkbox"
                  id="hide_question"
                  name="hide_question"
                  ref={hideQuestion}
                />
                <label htmlFor="hide_question" style={{ marginLeft: 10 }}>
                  <b>Move to Next Video?</b>
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="is_start"
                  name="is_start"
                  ref={isStartNode}
                  disabled={nodes.some(
                    (node) => node.is_start && node.id != nodeData?.id,
                  )}
                />
                <label htmlFor="is_start" style={{ marginLeft: 10 }}>
                  <b>Start</b>
                </label>
                <input
                  type="checkbox"
                  id="is_end"
                  name="is_end"
                  ref={isEndNode}
                />
                <label htmlFor="is_end" style={{ marginLeft: 10 }}>
                  <b>End</b>
                </label>
              </div>
            </div>
          </div>
          <hr className={styles.NewProjectHr} />
          <div className={styles.timerDiv}>
            <div>
              <input
                type="checkbox"
                id="show_timer"
                name="show_timer"
                ref={shouldShowTimer}
              />
              <label htmlFor="show_timer" style={{ marginLeft: 10 }}>
                <b>Show Timer</b>
              </label>
            </div>
            <div>
              <label htmlFor="display_time" style={{ marginRight: 10 }}>
                <b>Timer Duration</b>
              </label>
              <input
                className={styles.timerInput}
                id="display_time"
                type="number"
                min="0"
                max="10000"
                ref={displayTime}
              />
            </div>
          </div>

          <hr className={styles.NewProjectHr} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: 12,
              fontWeight: 600,
              textOverflow: "ellipsis",
            }}
          >
            <label
              htmlFor="choose_image_file"
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <input
                type="file"
                onChange={onPicInput}
                id={`choose_image_file`}
                name={`choose_image_file`}
                accept="image/*"
                className={styles.fileUpload}
              />
              <AddAPhotoIcon
                style={{
                  color: "#F33A6A",
                  marginRight: 10,
                  width: "20px",
                  height: "20px",
                }}
              />
              {imageUrl ? "Image Uploaded" : "Add Image"}
            </label>

            <label
              htmlFor="choose_video_file"
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {progress > 0 && (
                <CircularProgressWithLabel value={progress} sx={{ mr: 1 }} />
              )}
              <input
                type="file"
                onChange={uploadFile}
                id={`choose_video_file`}
                name={`choose_video_file`}
                accept="video/mp4,video/x-m4v,video/*"
                className={styles.fileUpload}
              />
              <VideocamIcon style={{ color: "#F33A6A", marginRight: 10 }} />
              {videoUrl ? "File Uploaded" : "Add Video"}
            </label>
          </div>
          <hr className={styles.NewProjectHr} />
          <Tabs
            value={choiceValue}
            className={styles.tabSection}
            onChange={handleChange}
            centered
          >
            {choices.map((choice) => (
              <Tab
                key={choice.choiceNumber}
                label={`Choice ${choice.choiceNumber}`}
                className={styles.tabValues}
              />
            ))}
            <Tab label="+ Add Choice" className={styles.tabValues} />
          </Tabs>
          {choices.length ? (
            <ChoiceDetailForm
              choiceData={choices[choiceValue]}
              nodes={nodes}
              changeChoiceData={changeChoiceData}
              onDeleteChoice={onDeleteChoice}
            />
          ) : null}
          <button
            type="button"
            onClick={handleSubmit}
            className={styles.submitButton}
          >
            Submit
          </button>
        </form>
      </div>
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          <div style={{ display: value == 1 ? "block" : "none" }}>
            <Matching
              nodes={nodes}
              edges={edges}
              setChoices={setChoices}
              handleSubmit={handleSubmit}
              matchingData={matchingData}
              setMatchingData={setMatchingData}
            />
          </div>
          <div style={{ display: value == 2 ? "block" : "none" }}>
            <DragAndDrop
              nodes={nodes}
              edges={edges}
              setChoices={setChoices}
              handleSubmit={handleSubmit}
              dragAndDrop={dragAndDrop}
              setDragAndDrop={setDragAndDrop}
            />
          </div>
        </>
      )}
    </div>
  );
}
