import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import styles from "../css/colorInput.module.scss";
import CancelIcon from "@mui/icons-material/Cancel";

const ColorInput = ({
  colors,
  changeChoiceData,
  choiceNumber,
  choiceButtonText,
}) => {
  let colorsInfo = colors;
  const ref = useRef(null);

  useEffect(() => {
    const handleInput = (event) => {
      event.preventDefault();
    };
    document.body.addEventListener("submit", handleInput);
  }, [ref]);

  const removeTags = (indexToRemove) => {
    colorsInfo = [...colors.filter((_, index) => index !== indexToRemove)];
    const myJSON = JSON.stringify(colorsInfo);
    changeChoiceData(choiceNumber, "colors", myJSON);
  };
  const addTags = (event) => {
    if (event.target.value !== "") {
      colorsInfo = [...colors, event.target.value.replace(/ /g, "")];
      const myJSON = JSON.stringify(colorsInfo);
      changeChoiceData(choiceNumber, "colors", myJSON);
      event.target.value = "";
    }
  };
  return (
    <div
      className={styles.ootagsInput}
      style={{
        cursor: choiceButtonText ? "not-allowed" : "text",
      }}
    >
      <ul id={styles.ootags}>
        {colors.map((color, index) => (
          <li key={index} className={styles.ootag}>
            <span className={styles.ootagTitle}>{color}</span>
            <CancelIcon
              className={styles.ootagCloseIcon}
              onClick={() => removeTags(index)}
            />
          </li>
        ))}
      </ul>
      <input
        disabled={choiceButtonText}
        style={{
          cursor: choiceButtonText ? "not-allowed" : "text",
        }}
        type="text"
        ref={ref}
        placeholder="Colours"
        onKeyUp={(event) => (event.key === "Enter" ? addTags(event) : null)}
      />
    </div>
  );
};

export default React.memo(ColorInput);
