import React, { useEffect, useRef } from "react";
import "../css/DragAndDropPlayGround.css";
import { Grid } from "@mui/material";
import ExerciseHeader from "./ExerciseHeader.js";

const DragAndDropPlayGround = ({
  dragAndDrop,
  currentNode,
  onClickingChoice,
  edges,
  setIsDnD,
}) => {
  let correct = 0;
  localStorage.setItem("score", "0");
  const totalDraggableItems = dragAndDrop?.length || 5;
  const totalMatchingPairs = dragAndDrop?.length || 5; // Should be <= totalDraggableItems

  const scoreSection = useRef();
  const correctSpan = useRef();
  const totalSpan = useRef();
  const draggableItems = useRef();
  const matchingPairs = useRef();
  let draggableElements;
  let droppableElements;

  useEffect(() => {
    initiateGame();
  }, []);

  function initiateGame() {
    const randomDraggableBrands = generateRandomItemsArray(
      totalDraggableItems,
      // brands
      dragAndDrop,
    );
    const randomDroppableBrands =
      totalMatchingPairs < totalDraggableItems
        ? generateRandomItemsArray(totalMatchingPairs, randomDraggableBrands)
        : randomDraggableBrands;
    const alphabeticallySortedRandomDroppableBrands = [
      ...randomDroppableBrands,
    ].sort((a, b) =>
      // a.brandName.toLowerCase().localeCompare(b.brandName.toLowerCase())
      a.objectName.toLowerCase().localeCompare(b.objectName.toLowerCase()),
    );

    // Create "draggable-items" and append to DOM
    if (draggableItems) {
      for (let i = 0; i < randomDraggableBrands.length; i++) {
        draggableItems.current.insertAdjacentHTML(
          "beforeend",
          // `<i class="fab fa-${randomDraggableBrands[i].iconName} draggable" draggable="true" style="color: ${randomDraggableBrands[i].color};" id="${randomDraggableBrands[i].iconName}"></i>`
          `<img class="draggable" src=${randomDraggableBrands[i].imageUrl} alt=${randomDraggableBrands[i].objectName} id="${randomDraggableBrands[i].objectName}" draggable="true"/>`,
        );
      }
    }

    // Create "matching-pairs" and append to DOM
    for (let i = 0; i < alphabeticallySortedRandomDroppableBrands.length; i++) {
      matchingPairs.current.insertAdjacentHTML(
        "beforeend",
        `<div class="matching-pair">
              <span class="droppable" data-brand="${alphabeticallySortedRandomDroppableBrands[i].objectName}"></span>
              <span class="label">${alphabeticallySortedRandomDroppableBrands[i].objectName}</span>
         </div>`,
      );
    }

    draggableElements = document.querySelectorAll(".draggable");
    droppableElements = document.querySelectorAll(".droppable");

    draggableElements.forEach((elem) => {
      elem.addEventListener("dragstart", dragStart);
      // elem.addEventListener("drag", drag);
      // elem.addEventListener("dragend", dragEnd);
    });

    droppableElements.forEach((elem) => {
      elem.addEventListener("dragenter", dragEnter);
      elem.addEventListener("dragover", dragOver);
      elem.addEventListener("dragleave", dragLeave);
      elem.addEventListener("drop", drop);
    });
  }

  // Drag and Drop Functions

  //Events fired on the drag target

  function dragStart(event) {
    event.dataTransfer.setData("text", event.target.id); // or "text/plain"
  }

  //Events fired on the drop target

  function dragEnter(event) {
    if (
      event.target.classList &&
      event.target.classList.contains("droppable") &&
      !event.target.classList.contains("dropped")
    ) {
      event.target.classList.add("droppable-hover");
    }
  }

  function dragOver(event) {
    if (
      event.target.classList &&
      event.target.classList.contains("droppable") &&
      !event.target.classList.contains("dropped")
    ) {
      event.preventDefault();
    }
  }

  function dragLeave(event) {
    if (
      event.target.classList &&
      event.target.classList.contains("droppable") &&
      !event.target.classList.contains("dropped")
    ) {
      event.target.classList.remove("droppable-hover");
    }
  }

  function drop(event) {
    event.preventDefault();
    event.target.classList.remove("droppable-hover");
    const draggableElementBrand = event.dataTransfer.getData("text");
    const droppableElementBrand = event.target.getAttribute("data-brand");
    const isCorrectMatching = draggableElementBrand === droppableElementBrand;
    // total++;
    const draggableElement = document.getElementById(draggableElementBrand);
    event.target.classList.add("dropped");
    draggableElement.classList.add("dragged");
    draggableElement.setAttribute("draggable", "false");
    // event.target.innerHTML = `<i class="fab fa-${draggableElementBrand}" style="color: ${draggableElement.style.color};"></i>`;
    const image = dragAndDrop.filter(
      (item) => item.objectName === draggableElementBrand,
    );
    event.target.innerHTML = `<img src=${image[0].imageUrl} alt=${draggableElementBrand} style="height:6.5rem; width:6.5rem; object-fit: cover"/>`;
    if (isCorrectMatching) {
      correct++;
      localStorage.setItem("score", JSON.stringify(correct));
    }
    scoreSection.current.style.opacity = 0;
    setTimeout(() => {
      correctSpan.current.textContent = correct;
      // totalSpan.current.textContent = total;
      scoreSection.current.style.opacity = 1;
    }, 200);
    if (correct === Math.min(totalMatchingPairs, totalDraggableItems)) {
      // Game Over!!
      // playAgainBtn.current.style.display = "block";
      // setTimeout(() => {
      //   playAgainBtn.current.classList.add("play-again-btn-entrance");
      // }, 200);
    }
  }

  // Auxiliary functions
  function generateRandomItemsArray(n, originalArray) {
    let res = [];
    let clonedArray = [...originalArray];
    if (n > clonedArray.length) n = clonedArray.length;
    for (let i = 1; i <= n; i++) {
      const randomIndex = Math.floor(Math.random() * clonedArray.length);
      res.push(clonedArray[randomIndex]);
      clonedArray.splice(randomIndex, 1);
    }
    return res;
  }
  return (
    <>
      <ExerciseHeader
        exerciseName="Drag And Drop"
        data={dragAndDrop}
        currentNode={currentNode}
        edges={edges}
        onClickingChoice={onClickingChoice}
        setIsDnD={setIsDnD}
      />
      <section className="score" ref={scoreSection}>
        <span className="correct" ref={correctSpan}>
          0
        </span>
        /
        <span className="total" ref={totalSpan}>
          {dragAndDrop.length}
        </span>
      </section>
      <Grid container>
        <Grid item xs={6}>
          <section className="draggable-items" ref={draggableItems}>
            {/* <!-- Will be dynamically populated - Example Element: --> */}
            {/* <!-- <i className="fab fa-codepen draggable" draggable="true" style="color: #111111;" id="codepen"></i> --> */}
          </section>
        </Grid>
        <Grid item xs={6}>
          <section className="matching-pairs" ref={matchingPairs}>
            {/* <!-- Will be dynamically populated - Example Element: --> */}
            {/* <!-- <div className="matching-pair"> */}
            {/* <span className="label">Codepen</span>
                <span className="droppable" data-brand="codepen"></span> */}
            {/* </div> --> */}
          </section>
        </Grid>
      </Grid>
    </>
  );
};

export default DragAndDropPlayGround;
