import { Divider } from "@mui/material";
import "../css/ChooseImage.css";

function ChooseImage({ imageUrl, objectName, changeColumnData, index }) {
  function getImgData(e) {
    const file = e.target.files[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.addEventListener("load", function () {
        changeColumnData(index, {
          imageUrl: this.result,
          fileName: file.name,
          mimeType: file.type,
        });
      });
    }
  }
  return (
    <>
      <div className="chooseImageContainer">
        {imageUrl ? (
          <img src={imageUrl} alt={objectName} id="img_preview" />
        ) : (
          <div id="img_preview"></div>
        )}
        <input
          type="file"
          id={`choose_file${index}`}
          name={`choose_file${index}`}
          accept="image/*"
          onChange={getImgData}
        />
        <label htmlFor={`choose_file${index}`}>Choose File</label>
        <Divider sx={{ my: 1, width: "100%" }} />
      </div>
    </>
  );
}
export default ChooseImage;
