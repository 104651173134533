import React, { useEffect, useState } from "react";

const ExerciseHeader = ({
  data,
  currentNode,
  onClickingChoice,
  edges,
  setIsMatching,
  setIsDnD,
  exerciseName,
}) => {
  const [seconds, setSeconds] = useState(20);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(interval);
      let score = localStorage.getItem("score");
      const choices = edges.filter((edge) => edge.source == currentNode?.id);
      if (exerciseName === "Drag And Drop") {
        setIsDnD(false);
      } else setIsMatching(false);
      if (parseInt(score) === data.length) {
        onClickingChoice(choices[0].nextLinkChoice, `All Correct Answer`);
      }
      if (parseInt(score) < data.length) {
        onClickingChoice(choices[1].nextLinkChoice, `Incorrect Answer`);
      }
      localStorage.removeItem("score");
    }, 20000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <h1 className="heading">
      {exerciseName} | Time Remaining: {seconds} seconds
    </h1>
  );
};

export default ExerciseHeader;
