export const formatName = (name) => {
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
};

export const validateFileSize = (inputFile, standardSize) =>
  inputFile.size / 1024 > standardSize
    ? `Maximum Allowed file size is ${standardSize} KB. Please set the size and retry uploading.`
    : "";

export const errorMessageFromResponse = (resp) => {
  let errorMsg = "";
  // console.log(resp);
  if (typeof resp === "object") {
    const errorObj = resp?.response?.data?.error;
    errorMsg = Object.keys(errorObj).includes("message")
      ? errorObj?.message
      : Object.values(errorObj)[0];
  } else if (typeof resp === "string") {
    errorMsg = resp;
  }
  return errorMsg;
};
