import axios from "axios";
import { store } from "../store";
import {
  CREATE_CHOICE,
  CREATE_NODE,
  DELETE_CHOICE,
  DELETE_NODE,
  GET_PROJECT_DETAILS,
  UPDATE_CHOICE,
  UPDATE_NODE,
  UPDATE_VIDEO_LINK,
} from "../constants/actionTypes";
import {
  CREATE_CHOICE_API,
  CREATE_NODE_API,
  DELETE_CHOICE_API,
  DELETE_NODE_API,
  GET_PROJECT_DETAILS_API,
  UPDATE_CHOICE_API,
  UPDATE_NODE_API,
} from "../constants/apiEndpoints";
import { handleErrorMessage, handleSuccessMessage } from "./projects";

export const getProjectDetails = (id) => {
  const token = store.getState()?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "get",
      url: `${GET_PROJECT_DETAILS_API}${id}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.status) {
          const data = response.data?.data;
          const choicesData = data?.nodes
            .flatMap((node) => node.choices)
            .map((choice) => {
              const {
                id,
                button_background_color,
                button_text_color,
                button_text,
                show_button_text,
                next_link,
                associated_node,
                colors,
                is_no_response,
                score,
              } = choice;
              return {
                id: id.toString(),
                arrowHeadType: "arrowclosed",
                source: associated_node.toString(),
                target: next_link.toString(),
                nextLinkChoice: next_link,
                choiceButtonText: button_text,
                showButtonText: show_button_text,
                choiceButtonTextColor: button_text_color,
                choiceButtonBackgroundColor: button_background_color,
                colors: colors,
                is_no_response: is_no_response,
                score: score,
              };
            });
          const nodesData = data?.nodes.map((node, index) => {
            return {
              id: node.id.toString(),
              description: node.description,
              type: "special",
              position: { x: node.position_x, y: node.position_y },
              data: {
                name: node.name,
                nodeCount: index + 1,
                description: node.description,
                is_start: node.is_start,
                is_end: node.is_end,
              },
              is_start: node.is_start,
              is_end: node.is_end,
              display_time: node.display_time,
              external_link: node.external_link,
              is_pronounciation: node.is_pronounciation,
              word_to_pronounce: node.word_to_pronounce,
              hide_onscreen_question: node.hide_onscreen_question,
              image_file: node.image_file,
              name: node.name,
              onscreen_question: node.onscreen_question,
              show_timer: node.show_timer,
              video_file: node.video_file,
              matching: node.matching,
              drag_and_drop_matching: node.drag_and_drop_matching,
            };
          });
          const projectData = {
            id: data.id,
            name: data.name,
            created_by: data.created_by,
            description: data.description,
          };
          const data1 = {
            project: projectData,
            nodes: nodesData,
            edges: choicesData,
          };
          dispatch({
            type: GET_PROJECT_DETAILS,
            payload: data1,
          });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(error));
        return error;
      });
  };
};

export const createNode = (data, nodeCount) => {
  const token = store.getState()?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "post",
      url: CREATE_NODE_API,
      data: data,
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.status) {
          const node = response?.data?.data;
          dispatch({
            type: CREATE_NODE,
            payload: {
              id: node.id.toString(),
              description: node.description,
              type: "special",
              position: { x: node.position_x, y: node.position_y },
              is_start: node.is_start,
              is_end: node.is_end,
              data: {
                name: node.name,
                nodeCount: nodeCount + 1,
                description: node.description,
              },
              display_time: node.display_time,
              external_link: node.external_link,
              hide_onscreen_question: node.hide_onscreen_question,
              is_pronounciation: node.is_pronounciation,
              word_to_pronounce: node.word_to_pronounce,
              image_file: node.image_file,
              name: node.name,
              onscreen_question: node.onscreen_question,
              show_timer: node.show_timer,
              video_file: node.video_file,
              drag_and_drop_matching: node.drag_and_drop_matching,
              matching: node.matching,
            },
          });
          dispatch(handleSuccessMessage("Node is added successfully!"));
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(error));
        return error;
      });
  };
};

export const updateNode = (data, nodeId) => {
  const token = store.getState()?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "put",
      url: `${UPDATE_NODE_API}${nodeId}/`,
      data: data,
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.status) {
          const node = response?.data?.data;
          dispatch({
            type: UPDATE_NODE,
            payload: {
              id: node.id.toString(),
              description: node.description,
              type: "special",
              position: { x: node.position_x, y: node.position_y },
              is_start: node.is_start,
              is_end: node.is_end,
              display_time: node.display_time,
              external_link: node.external_link,
              hide_onscreen_question: node.hide_onscreen_question,
              is_pronounciation: node.is_pronounciation,
              word_to_pronounce: node.word_to_pronounce,
              image_file: node.image_file,
              name: node.name,
              onscreen_question: node.onscreen_question,
              show_timer: node.show_timer,
              video_file: node.video_file,
            },
          });
          dispatch(handleSuccessMessage("Node is updated successfully!"));
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(error));
        return error;
      });
  };
};
export const deleteNode = (nodeId) => {
  const token = store.getState()?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "delete",
      url: `${DELETE_NODE_API}${nodeId}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.status >= 200) {
          dispatch({
            type: DELETE_NODE,
            payload: nodeId.toString(),
          });
          dispatch(handleSuccessMessage("Node Removed Successfully!"));
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(error));
        return error;
      });
  };
};
export const createEdge = (data) => {
  const token = store.getState()?.user?.token;
  const data1 = {
    associated_node: data?.source,
    next_link: data?.target,
  };
  return async (dispatch) => {
    await axios({
      method: "post",
      url: CREATE_CHOICE_API,
      data: data1,
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status) {
          const choice = response?.data?.data;
          const {
            id,
            button_background_color,
            button_text_color,
            button_text,
            show_button_text,
            next_link,
            associated_node,
          } = choice;
          const choiceData = {
            id: id.toString(),
            arrowHeadType: "arrowclosed",
            source: associated_node.toString(),
            target: next_link.toString(),
            choiceButtonText: button_text,
            nextLinkChoice: next_link.toString(),
            showButtonText: show_button_text,
            choiceButtonTextColor: button_text_color,
            choiceButtonBackgroundColor: button_background_color,
          };
          dispatch({
            type: CREATE_CHOICE,
            payload: choiceData,
          });
          dispatch(handleSuccessMessage("Edge Added!"));
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(error));
        return error;
      });
  };
};

export const updateEdge = (data, choiceId) => {
  const token = store.getState()?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "put",
      url: `${UPDATE_CHOICE_API}${choiceId}/`,
      data: data,
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status) {
          const choice = response?.data?.data;
          const {
            id,
            button_background_color,
            button_text_color,
            button_text,
            show_button_text,
            next_link,
            associated_node,
          } = choice;
          const choiceData = {
            id: id.toString(),
            arrowHeadType: "arrowclosed",
            source: associated_node.toString(),
            target: next_link.toString(),
            choiceButtonText: button_text,
            nextLinkChoice: next_link.toString(),
            showButtonText: show_button_text,
            choiceButtonTextColor: button_text_color,
            choiceButtonBackgroundColor: button_background_color,
          };
          dispatch({
            type: UPDATE_CHOICE,
            payload: choiceData,
          });
          dispatch(handleSuccessMessage("Node is updated successfully!"));
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(error));
        return error;
      });
  };
};

export const deleteEdge = (choiceId) => {
  const token = store.getState()?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "delete",
      url: `${DELETE_CHOICE_API}${choiceId}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.status >= 200) {
          dispatch({
            type: DELETE_CHOICE,
            payload: choiceId.toString(),
          });
          dispatch(handleSuccessMessage("Edge Removed!"));
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(error));
        return error;
      });
  };
};

export const updateVideoLink = (link, nodeId) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_VIDEO_LINK, payload: { link, nodeId } });
  };
};
