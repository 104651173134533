import { ReactP5Wrapper } from "react-p5-wrapper";
import "../css/matchingPlayGround.css";
import ExerciseHeader from "./ExerciseHeader";

const MatchingPlayGround = ({
  data,
  currentNode,
  onClickingChoice,
  edges,
  setIsMatching,
}) => {
  function sketch(p) {
    let Column1 = [];
    let Column2 = [];
    let ShuffledColumn2 = [];
    let sa = null;
    let lined = { x1: null, y1: null, x2: null, y2: null };
    let toggle = false;
    let onbox = false;
    let score = 0;
    let lines = [];
    localStorage.setItem("score", "0");

    p.setup = function () {
      let width = Number(window.innerWidth);
      let height = Number(window.innerHeight) - 90;

      p.createCanvas(width, height);

      border();

      data.map((e) => {
        ShuffledColumn2.push(e.column2Data);
      });

      //shuffling the Column2
      ShuffledColumn2 = p.shuffle(ShuffledColumn2);

      //displaying the boxes
      data.map((e, index) => {
        Column1.push(new Column1Data(500, index * 100 + 20, e.column1Data)); //(x-index, y-index, column1Data)
        Column2.push(
          new Column2Data(850, index * 100 + 20, ShuffledColumn2[index]),
        ); //(x-index, y-index, Shuffled Column2)
      });
    };

    //clears the screen between renderings
    const border = () => {
      p.strokeWeight(1);
      p.stroke(0);
      p.background(255, 200, 200);
    };

    p.mouseClicked = () => {
      if (!toggle) {
        lined.x1 = p.mouseX;
        lined.y1 = p.mouseY;
        // console.log(lined);
      } else {
        lined.x2 = p.mouseX;
        lined.y2 = p.mouseY;
        // console.log(lined);
        for (let i = 0; i < Column1.length; i++) {
          if (Column1[i].selected) {
            console.log(Column1[i]);
            for (let j = 0; j < Column2.length; j++) {
              if (Column2[j].hover(p.mouseX, p.mouseY)) {
                console.log(Column2[i]);
                //pushing lines drawn into an array for rendering
                lines.push(new Connect(lined.x1, lined.y1, lined.x2, lined.y2));
                //reseting line-holder
                lined = { x1: null, y1: null, x2: null, y2: null };
                break;
              }
            }
          }
        }
      }

      toggle = !toggle;
      console.log(toggle);

      for (let i = 0; i < Column1.length; i++) {
        //processing clicks on Column1
        // console.log(Column1[i]);
        Column1[i].click(p.mouseX, p.mouseY);
      }

      for (let i = 0; i < Column2.length; i++) {
        //processing clicks on Column2
        // console.log(Column2[i]);
        Column2[i].click(p.mouseX, p.mouseY);
      }
    };

    p.draw = function () {
      border();

      for (let i = 0; i < Column1.length; i++) {
        Column1[i].show(p.mouseX, p.mouseY);
        if (Column1[i].selected) {
          p.strokeWeight(10);
          p.stroke(30, 200, 190);
          p.line(
            Column1[i].x + Column1[i].w,
            Column1[i].y + Column1[i].h / 2,
            p.mouseX,
            p.mouseY,
          );
          p.fill(255, 0, 0);
          p.strokeWeight(2);
          p.circle(
            Column1[i].x + Column1[i].w,
            Column1[i].y + Column1[i].h / 2,
            10,
          );
        }
      }

      for (let i = 0; i < Column2.length; i++) {
        p.strokeWeight(1);
        p.stroke(0);
        Column2[i].show(p.mouseX, p.mouseY);
      }

      for (let i = 0; i < lines.length; i++) {
        let l = new Connect(lines[i].x, lines[i].y, lines[i].xx, lines[i].yy);
        l.show(l.x, l.y, l.xx, l.yy);
      }

      //Display score

      p.stroke(0);
      p.strokeWeight(1);
      p.fill(0);
      p.text("Score: " + score, 745, 525);
    };
    class Connect {
      constructor(x, y, xx, yy) {
        this.x = x; //x1
        this.y = y; //y1
        this.xx = xx; //x2
        this.yy = yy; //y2
      }
      show() {
        p.strokeWeight(10);
        p.stroke("rgba(54, 69, 79,0.5)");
        p.circle(this.x, this.y, 10);
        p.line(this.x, this.y, this.xx, this.yy);
        p.circle(this.xx, this.yy, 10);
      }
    }
    class Column2Data {
      constructor(x, y, name) {
        this.selected = false;
        this.answered = false;
        this.answer = false;
        this.x = x;
        this.y = y;
        this.name = name;
        this.w = 200;
        this.h = 50;
      }

      show(mx, my) {
        if (this.answer) {
          //correct answer
          p.noStroke();
          p.fill(0, 200, 0);
          p.rect(this.x, this.y, this.w, this.h);
          p.textSize(20);
          p.fill(255);
          p.text(this.name, this.x + this.w / 8, this.y + this.h - 15);
        } else {
          //incorrect answer
          if (this.answered) {
            p.noStroke();
            p.fill(200, 0, 0);
            p.rect(this.x, this.y, this.w, this.h);
            p.textSize(20);
            p.fill(255);
            p.text(this.name, this.x + this.w / 8, this.y + this.h - 15);
          } else {
            //not yet answered
            p.noStroke();
            p.fill(this.hover(mx, my) ? 120 : 255);
            p.rect(this.x, this.y, this.w, this.h);
            p.textSize(20);
            p.fill(this.hover(mx, my) ? 255 : 120);
            p.text(this.name, this.x + this.w / 8, this.y + this.h - 15);
          }
        }
      }
      //are we touching the box?
      hover(x, y) {
        if (
          x > this.x &&
          x < this.x + this.w &&
          y > this.y &&
          y < this.y + this.h
        ) {
          return true;
        } else {
          return false;
        }
      }

      click(x, y) {
        this.hover(x, y) ? (this.selected = true) : (this.selected = false);
        //onbox, so we don't draw lines outside the boxes
        this.hover(x, y) ? (onbox = true) : (onbox = false);

        if (this.selected && sa !== null) {
          if (this.name === sa) {
            this.answer = true;
            this.answered = true;
            score += 1;
            // console.log(score);
            localStorage.setItem("score", JSON.stringify(score));
            sa = null;
          } else {
            this.answer = false;
            this.answered = true;
            sa = null;
          }
        }
        return this.answer;
      }
    }
    class Column1Data {
      constructor(x, y, name) {
        this.selected = false;
        this.answered = false;
        this.x = x;
        this.y = y;
        this.name = name;
        this.w = 200;
        this.h = 50;
      }

      show(mx, my) {
        p.noStroke();
        p.fill(this.hover(mx, my) ? 120 : 255);
        p.rect(this.x, this.y, this.w, this.h);
        p.textSize(20);
        p.fill(this.hover(mx, my) ? 255 : 120);
        p.text(this.name, this.x + this.w / 8, this.y + this.h - 15);
      }

      hover(x, y) {
        if (
          x > this.x &&
          x < this.x + this.w &&
          y > this.y &&
          y < this.y + this.h
        ) {
          return true;
        } else {
          return false;
        }
      }

      click(x, y) {
        this.hover(x, y) ? (this.selected = true) : (this.selected = false);
        this.hover(x, y) ? (onbox = true) : (onbox = false);
        if (this.selected) {
          sa =
            data[data.findIndex((i) => i.column1Data === this.name)]
              .column2Data;
          //given the click, find the column2Data
        }
        return this.selected;
      }
    }
  }
  return (
    <>
      <ExerciseHeader
        exerciseName="Match the following"
        data={data}
        currentNode={currentNode}
        onClickingChoice={onClickingChoice}
        edges={edges}
        setIsMatching={setIsMatching}
      />
      <ReactP5Wrapper sketch={sketch} />
    </>
  );
};

export default MatchingPlayGround;
