import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleErrorMessage,
  handleSuccessMessage,
} from "../redux/actions/projects";

export default function ApiResponseAlert() {
  const successMessage = useSelector((state) => state.apiResponse?.successMsg);
  const errorMessage = useSelector((state) => state.apiResponse?.errorMsg);

  const dispatch = useDispatch();
  const alertCloser = () => {
    if (errorMessage != "") dispatch(handleErrorMessage(""));
    if (successMessage != "") dispatch(handleSuccessMessage(""));
  };
  return (
    <div>
      <Snackbar
        open={successMessage !== "" || errorMessage !== "" ? true : false}
        autoHideDuration={5000}
        onClose={alertCloser}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={alertCloser}
          severity={successMessage !== "" ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {successMessage || errorMessage || ""}
        </Alert>
      </Snackbar>
    </div>
  );
}
