import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../css/newProjectForm.module.css";
import { createProject } from "../redux/actions/projects";

export default function NewProjectForm() {
  const projectNameRef = useRef();
  const descriptionRef = useRef();
  const createdByRef = useRef();
  const user = useSelector((state) => state?.user);
  const dispatch = useDispatch();

  function handleSubmit(event) {
    event.preventDefault();
    const data = {
      user: user?.user_id,
      name: projectNameRef.current.value,
      description: descriptionRef.current.value,
      created_by: createdByRef.current.value,
    };
    dispatch(createProject(data)).then(() => {
      projectNameRef.current.value = "";
      descriptionRef.current.value = "";
      createdByRef.current.value = "";
    });
  }

  return (
    <div className={styles.formContainer}>
      <h1>New Project</h1>
      <p>Please fill in this form to create new project.</p>
      <hr className={styles.NewProjectHr} />
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">
            <b>Project Name</b>
          </label>
          <input
            className={styles.descriptionInput}
            id="name"
            type="text"
            ref={projectNameRef}
          />
        </div>
        <div>
          <label htmlFor="description">
            <b>Description</b>
          </label>
          <textarea
            className={styles.descriptionInput}
            id="description"
            ref={descriptionRef}
          />
        </div>
        <div>
          <label htmlFor="creator">
            <b>Creator</b>
          </label>
          <input
            className={styles.descriptionInput}
            id="createdBy"
            type="text"
            ref={createdByRef}
          />
        </div>
        <hr className={styles.NewProjectHr} />
        <button type="submit" className={styles.submitButton}>
          Submit
        </button>
      </form>
    </div>
  );
}
