import { combineReducers } from "redux";
import projects from "./reducers/projects";
import nodes from "./reducers/nodes";
import user from "./reducers/user";
import apiResponse from "./reducers/apiResponse";

export default combineReducers({
  projects,
  nodes,
  apiResponse,
  user,
});
