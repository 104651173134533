import React from "react";
import { Handle, Position } from "react-flow-renderer";
import styles from "../css/node.module.css";

export default function Node(data) {
  const name = data?.data?.name;
  const nodeCount = data?.data?.nodeCount;
  const description = data?.data?.description;
  const nodeNumber = data?.id;
  const isStartNode = data?.data?.is_start;
  const isEndNode = data?.data?.is_end;
  return (
    <div className={styles.nodeContainer}>
      {isStartNode ? null : (
        <Handle
          type="target"
          position={Position.Left}
          style={{ borderRadius: 0 }}
          isConnectable={true}
        />
      )}
      <h5 className={styles.nodeHeading1}>
        {nodeCount}:{name || "Name"}
      </h5>
      <h5 className={styles.nodeHeading2}>{description || "Description"}</h5>
      {isStartNode ? (
        <div className={styles.nodeStartContainer}>Start</div>
      ) : null}
      {isEndNode ? <div className={styles.nodeStartContainer}>End</div> : null}
      <Handle
        type="source"
        position={Position.Right}
        id="b"
        style={{ top: "50%", borderRadius: 0 }}
        isConnectable={true}
      />
    </div>
  );
}
