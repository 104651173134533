import styles from "../css/nodeDetailsForm.module.css";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ColorInput from "./ColorInput";
import { FormControl, InputLabel, TextField } from "@mui/material";
import { useEffect, useState } from "react";

export default function ChoiceDetailForm({
  choiceData,
  nodes,
  changeChoiceData,
  onDeleteChoice,
}) {
  const {
    choiceNumber,
    choiceButtonText,
    choiceButtonBackgroundColor,
    choiceButtonTextColor,
    nextLinkChoice,
    colors,
    is_no_response,
    score,
  } = { ...choiceData };
  const [colorsData, setColorsData] = useState([]);
  useEffect(() => {
    if (colors) {
      setColorsData(JSON.parse(colors));
    }
  }, []);
  return (
    <>
      <div className={styles.deleteButton}>
        <DeleteForeverIcon
          onClick={() => onDeleteChoice(choiceData)}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div>
        <label
          htmlFor="button_text"
          style={{
            color: colorsData[0] ? "grey" : "",
          }}
        >
          <b>Choice {choiceNumber} Button text</b>
        </label>
        <input
          disabled={colorsData[0]}
          style={{
            cursor: colorsData[0] ? "not-allowed" : "text",
          }}
          className={styles.descriptionInput}
          id="button_text"
          type="text"
          onChange={(e) =>
            changeChoiceData(choiceNumber, "choiceButtonText", e.target.value)
          }
          value={choiceButtonText}
        />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" color="mui">
            Links to
          </InputLabel>
          <Select
            color="mui"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={nextLinkChoice}
            label="Links to"
            onChange={(e) =>
              changeChoiceData(choiceNumber, "nextLinkChoice", e.target.value)
            }
          >
            {nodes.map((node) => (
              <MenuItem key={node.id} value={node.id}>
                {node.id}-{node.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          color="mui"
          label="Score"
          type="score"
          value={score}
          fullWidth
          sx={{ mt: 3 }}
          onChange={(e) =>
            changeChoiceData(choiceNumber, "score", e.target.value)
          }
        />
        <div style={{ marginTop: "10px" }}>
          <input
            type="checkbox"
            id="is_pronounciation"
            name="is_pronounciation"
            checked={is_no_response}
            onChange={(e) => {
              changeChoiceData(
                choiceNumber,
                "is_no_response",
                e.target.checked,
              );
            }}
          />
          <label htmlFor="is_pronounciation" style={{ marginLeft: 10 }}>
            <b>For No Response?</b>
          </label>
        </div>
        {/* <Select
          label="Links to"
          onChange={(e) =>
            changeChoiceData(choiceNumber, "nextLinkChoice", e.target.value)
          }
          value={nextLinkChoice}
          className={styles.selectionInput}
        >
          <MenuItem key={'0'} value={'0'}>Leave Unlinked</MenuItem>
          {nodes.map((node) => (
            <MenuItem key={node.id} value={node.id}>
              {node.id}-{node.name}
            </MenuItem>
          ))}
        </Select> */}
        <ColorInput
          colors={colorsData}
          changeChoiceData={changeChoiceData}
          choiceNumber={choiceNumber}
          choiceButtonText={choiceButtonText}
        />
      </div>

      <div className={styles.colorInputDiv}>
        <label
          htmlFor="button_background_color"
          className={styles.colorInputLabel}
        >
          <b>Background</b>
        </label>
        <input
          type="color"
          id="button_background_color"
          onChange={(e) =>
            changeChoiceData(
              choiceNumber,
              "choiceButtonBackgroundColor",
              e.target.value,
            )
          }
          value={choiceButtonBackgroundColor}
        />
        <label htmlFor="button_text_color" className={styles.colorInputLabel}>
          <b>Text</b>
        </label>
        <input
          type="color"
          id="button_text_color"
          onChange={(e) =>
            changeChoiceData(
              choiceNumber,
              "choiceButtonTextColor",
              e.target.value,
            )
          }
          value={choiceButtonTextColor}
        />
      </div>
    </>
  );
}
