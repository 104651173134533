import { LogoutOutlined } from "@mui/icons-material";
import React from "react";
import { useDispatch } from "react-redux";
import styles from "../css/newProjectForm.module.css";
import ApiResponseAlert from "./ApiResponseAlert";
import NewProjectForm from "./NewProjectForm";
import ProjectsList from "./ProjectsList";
import { logoutUser } from "../redux/actions/user";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className={styles.mainContainer}>
      <ApiResponseAlert />
      <div
        className={styles.outerContainer}
        style={{ backgroundColor: "#FFF5EE", flex: 1.3 }}
      >
        <ProjectsList />
      </div>
      <div className={styles.outerContainer}>
        <div
          onClick={() => dispatch(logoutUser()).then(() => navigate("/signin"))}
          className={styles.headerText}
        >
          <LogoutOutlined style={{ marginRight: 5 }} />
          Log Out
        </div>
        <NewProjectForm />
      </div>
    </div>
  );
}
