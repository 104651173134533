import {
  GET_PROJECT_DETAILS,
  CREATE_NODE,
  UPDATE_NODE,
  CREATE_CHOICE,
  UPDATE_CHOICE,
  DELETE_CHOICE,
  DELETE_NODE,
  UPDATE_VIDEO_LINK,
} from "../constants/actionTypes";

const defaultState = {
  project: {},
  nodes: [],
  edges: [],
};
let projectData = {};
export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_PROJECT_DETAILS:
      projectData = { ...state };
      projectData.project = action.payload.project;
      projectData.nodes = action.payload.nodes;
      projectData.edges = action.payload.edges;
      return projectData;
    case CREATE_NODE:
      projectData = { ...state };
      projectData.nodes = [...projectData.nodes, action.payload];
      return projectData;
    case UPDATE_NODE:
      projectData = { ...state };
      projectData.nodes = [...projectData.nodes, action.payload];
      return projectData;
    case DELETE_NODE:
      projectData = { ...state };
      projectData.nodes = [
        ...projectData?.nodes.filter((node) => node.id != action.payload),
      ];
      return projectData;
    case CREATE_CHOICE:
      projectData = { ...state };
      projectData.edges = [...projectData.edges, action.payload];
      return projectData;
    case UPDATE_CHOICE:
      projectData = { ...state };
      projectData.edges = [...projectData.edges, action.payload];
      return projectData;
    case DELETE_CHOICE:
      projectData = { ...state };
      projectData.edges = [
        ...projectData?.edges.filter((edge) => edge.id != action.payload),
      ];
      return projectData;
    case UPDATE_VIDEO_LINK:
      projectData = { ...state };
      const index = state.nodes.findIndex(
        (node) => node.id === action.payload.nodeId,
      );
      projectData.nodes[index].video_file = action.payload.link;
      return projectData;
    default:
      return { ...state };
  }
};
